import React, {Component} from "react";
import {timezones, languageText} from "../config";
import DataOverview from "../components/DataOverview";
import Loader from "../components/layout/Loader";

const tableName = "user";

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organizationId: '',
            pageMode: "loading",
            searchColumns: [
                'f_name',
                'l_name',
                'email'
            ],
            tableColumns : [
                {
                    dataField: "status",
                    type: "bool",
                    sort: false,
                    filter: false,
                },
                {
                    dataField: "user_img",
                    type: "image",
                    sort: true,
                    filter: false,
                },
                {
                    dataField: "f_name",
                    text: "First Name",
                    sort: true,
                },
                {
                    dataField: "l_name",
                    text: "Last Name",
                    sort: true
                },
                {
                    dataField: "email",
                    text: "Email",
                    sort: true
                },
                {
                    dataField: "user_type",
                    text: "User Type",
                    type: "userType",
                    sort: true
                },
                {
                    dataField: "user_role",
                    text: "User Role",
                    type: "userRole",
                    sort: true
                },
                {
                    dataField: "dateCreated",
                    text: "Date Created",
                    name: "Created",
                    sort: true
                },
                {
                    dataField: "lastLoggedIn",
                    name: "Last Logged In",
                    sort: true,
                    filter: false,
                },
                {
                    dataField: "tools",
                    sort: false,
                    filter: false,
                }
            ],
            fieldData: [
                {
                    fieldName: "titleUsersName",
                    fieldType: "title",
                    colSize: 1
                },
                {
                    fieldName: "f_name",
                    fieldType: "text",
                    colSize: 3,
                    required: true,
                },
                {
                    fieldName: "l_name",
                    fieldType: "text",
                    colSize: 3,
                    required: true,
                },
                {
                    fieldName: "status",
                    fieldType: "toggle",
                    colSize: 3
                },
                {
                    fieldName: "titleLogInfo",
                    fieldType: "title",
                    colSize: 1
                },
                {
                    fieldName: "email",
                    fieldType: "email",
                    colSize: 1
                },
                {
                    fieldName: "password",
                    fieldType: "password",
                    colSize: 2
                },
                {
                    fieldName: "passwordConfirm",
                    fieldType: "password",
                    colSize: 2
                },
                {
                    fieldName: "titleContactInfo",
                    fieldType: "title",
                    colSize: 1
                },
                {
                    fieldName: "user_type",
                    fieldType: "dropdown",
                    colSize: 2,
                    dropdownData: [
                        {
                            id: 0,
                            name: 'Community',
                            value: '0'
                        },
                        {
                            id: 1,
                            name: 'Provider',
                            value: '1'
                        },
                        {
                            id: 2,
                            name: 'Volunteer',
                            value: '2'
                        },
                        {
                            id: 3,
                            name: 'Family',
                            value: '3'
                        }
                    ]
                },
                {
                    fieldName: "user_role",
                    fieldType: "dropdown",
                    colSize: 2,
                    dropdownData: [
                        {
                            id: 1,
                            name: 'Admin',
                            value: '1'
                        },
                        {
                            id: 2,
                            name: 'Regional',
                            value: '2'
                        },
                        {
                            id: 3,
                            name: 'Sales',
                            value: '3'
                        }
                    ]
                },
                {
                    fieldName: "started_industry",
                    fieldType: "text",
                    colSize: 2
                },
                {
                    fieldName: "title",
                    fieldType: "text",
                    colSize: 2
                },
                {
                    fieldName: "about",
                    fieldType: "textarea",
                    colSize: 1
                },
                {
                    fieldName: "education",
                    fieldType: "textarea",
                    colSize: 1
                },
                {
                    fieldName: "user_img",
                    fieldType: "image",
                    colSize: 1
                },
                {
                    fieldName: "cert_img",
                    fieldType: "drag",
                    colSize: 1
                }
            ],
            fieldDataDefault: {
            },
        }
    }
    componentDidMount() {
        this.setState({ pageMode: 'data'});
    }

    render() {
        const { pageMode, tableColumns, fieldData, fieldDataDefault, organizationId, searchColumns } = this.state;
        if (pageMode === "data") {
        return (<DataOverview
            tableName={tableName}
            organizationId={organizationId}
            searchColumns={searchColumns}
            tableColumns={tableColumns}
            fieldData={fieldData}
            fieldDataDefault={fieldDataDefault}
        />);}else{return(<Loader />);}
    }
}
export default Users;
