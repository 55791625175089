import React, { Component, Fragment} from "react";
import { Button, Card, CardHeader, CardTitle } from "reactstrap";
import { Save as SaveIcon, XCircle as CancelIcon } from "react-feather";
import CardBody from "reactstrap/es/CardBody";
import CardFooter from "reactstrap/es/CardFooter";
import FormInput from "./form/FormInput";
import Loader from "./layout/Loader";
import AdddressGoogleMap from "./google-maps/AddressGoogleMap";
import { cityList, languageText } from "../config";
import { getUserRoles, getCities, getStates } from "../client/actions/apiActions"
import {connect} from "react-redux";

class DataEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataFields: [],
            dataResults: [],
            loading: true
        }
        this.selectedCheckboxes = new Set();
    }
    componentDidMount() {
        this.props.getStates();
        let dataResults = this.props.dataResults;
        if (typeof(dataResults.languageList) !== "undefined") {
            let languageList = dataResults.languageList.split(",");
            languageList.map((options, index) => {
                if (options !== '' && typeof(options) !== 'undefined') {
                    this.selectedCheckboxes.add(options);
                }
                return {...options};
            });
        }

        // CHECKBOXES
        if (typeof(dataResults.adverseExperiences) !== "undefined") {
            let adverseExperiences = dataResults.adverseExperiences.split(",");
            if (typeof(this.selectedCheckboxes["adverseExperiences"]) === 'undefined')
            {
                this.selectedCheckboxes["adverseExperiences"] = new Set();
            }
            adverseExperiences.map((options, index) => {
                if (options !== '' && typeof(options) !== 'undefined') {
                    this.selectedCheckboxes["adverseExperiences"].add(options);
                }
                return {...options};
            });
        }
        if (typeof(dataResults.levelOfCare) !== "undefined") {
            let levelOfCare = dataResults.levelOfCare.split(",");
            if (typeof(this.selectedCheckboxes["levelOfCare"]) === 'undefined')
            {
                this.selectedCheckboxes["levelOfCare"] = new Set();
            }
            levelOfCare.map((options, index) => {
                if (options !== '' && typeof(options) !== 'undefined') {
                    this.selectedCheckboxes["levelOfCare"].add(options);
                }
                return {...options};
            });
        }
        if (typeof(dataResults.offerTypes) !== "undefined") {
            let offerTypes = dataResults.offerTypes.split(",");
            if (typeof(this.selectedCheckboxes["offerTypes"]) === 'undefined')
            {
                this.selectedCheckboxes["offerTypes"] = new Set();
            }
            offerTypes.map((options, index) => {
                if (options !== '' && typeof(options) !== 'undefined') {
                    this.selectedCheckboxes["offerTypes"].add(options);
                }
                return {...options};
            });
        }
        if (typeof(dataResults.programStatus) !== "undefined") {
            let programStatus = dataResults.programStatus.split(",");
            if (typeof(this.selectedCheckboxes["programStatus"]) === 'undefined')
            {
                this.selectedCheckboxes["programStatus"] = new Set();
            }
            programStatus.map((options, index) => {
                if (options !== '' && typeof(options) !== 'undefined') {
                    this.selectedCheckboxes["programStatus"].add(options);
                }
                return {...options};
            });
        }
        if (typeof(dataResults.traumaInformStatus) !== "undefined") {
            let traumaInformStatus = dataResults.traumaInformStatus.split(",");
            if (typeof(this.selectedCheckboxes["traumaInformStatus"]) === 'undefined')
            {
                this.selectedCheckboxes["traumaInformStatus"] = new Set();
            }
            traumaInformStatus.map((options, index) => {
                if (options !== '' && typeof(options) !== 'undefined') {
                    this.selectedCheckboxes["traumaInformStatus"].add(options);
                }
                return {...options};
            });
        }

        if (typeof(dataResults.userRole) !== 'undefined' && this.props.tableName === 'users') {
            let userRole = dataResults.userRole.split(",");
            if (typeof(this.selectedCheckboxes["userRole"]) === 'undefined')
            {
                this.selectedCheckboxes["userRole"] = new Set();
            }
            userRole.map((options, index) => {
                if (options !== '' && typeof(options) !== 'undefined') {
                    this.selectedCheckboxes["userRole"].add(options);
                }
                return {...options};
            });
            this.props.getUserRoles(this.props.organizationId);
        }

        if (typeof(dataResults.limitAccess) !== 'undefined' && this.props.tableName === 'users' && dataResults.userRole == 2) {
            let limitAccess = dataResults.limitAccess.split(",");
            if (typeof(this.selectedCheckboxes["limitAccess"]) === 'undefined')
            {
                this.selectedCheckboxes["limitAccess"] = new Set();
            }
            limitAccess.map((options, index) => {
                if (options !== '' && typeof(options) !== 'undefined') {
                    this.selectedCheckboxes["limitAccess"].add(options);
                }
                return {...options};
            });
        }
        
        if (typeof(dataResults.territory) !== 'undefined' && this.props.tableName === 'users' && dataResults.userRole == 2) {

            let dataFields = this.props.dataFields;
            this.setState({
                dataFields: dataFields
            }, () => {
                this.getCity(dataResults.territory);
            });
        }
       
        if (typeof(dataResults.cities) !== 'undefined' && this.props.tableName === 'users' && dataResults.userRole == 2 ) {
            let cities = dataResults.cities.split(",");
            if (typeof(this.selectedCheckboxes["cities"]) === 'undefined')
            {
                this.selectedCheckboxes["cities"] = new Set();
            }
            cities.map((options, index) => {
                if (options !== '' && typeof(options) !== 'undefined') {
                    this.selectedCheckboxes["cities"].add(options);
                }
                return {...options};
            });
        }

        if (typeof(dataResults.tablesCanAccess) !== 'undefined' && this.props.tableName === 'userRoles') {
            let tablesCanAccess = dataResults.tablesCanAccess.split(",");
            if (typeof(this.selectedCheckboxes["tablesCanAccess"]) === 'undefined')
            {
                this.selectedCheckboxes["tablesCanAccess"] = new Set();
            }
            tablesCanAccess.map((options, index) => {
                if (options !== '' && typeof(options) !== 'undefined') {
                    this.selectedCheckboxes["tablesCanAccess"].add(options);
                }
                return {...options};
            });
        }

        if (this.props.tableName === 'mentee' && this.props.languageTable === 'menteeUserPassword')
        {
            dataResults = {};
        }
        
        this.setState({
            dataResults: dataResults,
            loading: false
        });
    }
    
    getCity(state){
        this.props.getCities('state',state).then(todos => {
            
          

        if (typeof(this.state.dataResults.territory) !== 'undefined')
        {
            let dataFields = this.state.dataFields;
            let cityIndex = dataFields.findIndex((field) => {
                return (field.fieldName === 'cities');
            });

            if(this.state.dataFields.find((field) => (field.fieldName === 'cities')) !== undefined){

                let newCities = [];
                this.props.cities.forEach((options, index) => {
                    if (options.city !== '' && typeof(options.city) !== 'undefined' && options.city !== undefined) {
                        newCities.push({
                            name: options.city.split('_').join(' '),
                            value: options.id,
                            id: options.id
                        });
                    }
                });

                dataFields[cityIndex]['valueOptions'] = [{ name: 'All', value: 'all_city', id: 'all_city' },...newCities];
                                   
            }
            this.setState({ dataFields: dataFields });
        }
    });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props)
        {
            if (typeof(this.props.data.userRoles) !== 'undefined')
            {
                let dataFields = this.props.dataFields;
                dataFields.map((field, index) =>
                {
                    if (field.fieldName === 'userRole')
                    {
                        dataFields[index]["valueOptions"] = this.props.data.userRoles;
                    }
                    return null;
                });
                this.setState({ dataFields: dataFields });
            }

            if (typeof(this.props.data.userRoles) !== 'undefined')
            {
                let dataFields = this.props.dataFields;
                let userRoleIndex = dataFields.findIndex((field) => {
                    return (field.fieldName === 'limitAccess');
                });
                if(userRoleIndex !== undefined){
                    let displayClass = "hideDiv"; 
                    if((typeof(this.props.dataResults.userRole) !== 'undefined')){
                        if(this.props.dataResults.userRole == 2){
                            displayClass = "showDiv";
                        }
                    }
                    dataFields[userRoleIndex]['displayDiv'] = displayClass;
                    this.setState({ dataFields: dataFields });
                }
                
            }

            let dataFields = this.props.dataFields;
            let stateIndex = dataFields.findIndex((field) => {
                return (field.fieldName === 'territory');
            });
            if(stateIndex != -1 && Object.keys(this.props.states).length > 0){
                
                let newStates = [];
                this.props.states.forEach((options, index) => {
                    if (options.state !== '' && typeof(options.state) !== 'undefined' && options.state !== undefined) {
                        newStates.push({
                            name: options.state.split('_').join(' '),
                            short: options.state_code.toString()
                        });
                    }
                });

                dataFields[stateIndex]['dropdownData'] = newStates;
            }
        }
    }

    checkAllCity(type){
        if(type == "uncheck-all"){
            if (typeof(this.selectedCheckboxes["cities"]) !== 'undefined')
            {
                this.selectedCheckboxes["cities"] = new Set();
                this.selectedCheckboxes["cities"].add("all_city");
            }
        }else{
            let cities = (this.props.auth.user.cities && this.props.auth.user.userRole == 2)?this.props.auth.user.cities:this.props.cities;
            if (typeof(this.selectedCheckboxes["cities"]) === 'undefined')
            {
                this.selectedCheckboxes["cities"] = new Set();
            }
            
            cities.map((options, index) => {
                if (options !== '' && typeof(options) !== 'undefined') {
                    this.selectedCheckboxes["cities"].add(options.id);
                }
                return {...options};
            });
        }
    }

    setLimitAccess(role){
        if (typeof(this.props.dataResults.userRole) !== 'undefined')
        {
            let dataFields = this.props.dataFields;
            let limitAccessIndex = dataFields.findIndex((field) => {
                return (field.fieldName === 'limitAccess');
            });

            if(this.state.dataFields.find((field) => (field.fieldName === 'limitAccess')) !== undefined && role == 2){
                
                dataFields[limitAccessIndex]['displayDiv'] = 'showDiv';
                
            }else{

                dataFields[limitAccessIndex]['displayDiv'] = 'hideDiv';
                
                let dataResults = this.state.dataResults;
                if (typeof(dataResults.limitAccess) !== 'undefined'){
                    dataResults["limitAccess"] = "";
                    this.setState({ dataResults: dataResults });
                }
            }
            this.setState({ dataFields: dataFields });
        }
    }

    onChange = (type, e, field) => {
        let dataResults = this.state.dataResults;
        let tmpValue = '';
        if (type === 'text')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'phone')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'email')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'textarea')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'toggle')
        {
            if (e.target.checked === true) dataResults[field.fieldName] = '1';
            if (e.target.checked === false) dataResults[field.fieldName] = '0';
        }
        else if (type === 'image')
        {
            dataResults[field.fieldName] = e;
        }
        else if (type === 'editor')
        {
            dataResults[field.fieldName] = e.target.getContent();
        }
        else if (type === 'select' || type === 'dropdown')
        {
            dataResults[e.target.name] = e.target.value;
            if(field.fieldName == 'territory' && (e.target.value !== "")){
                dataResults["cities"] = "";
                this.selectedCheckboxes["cities"] = new Set();
                this.getCity(e.target.value);
            }
        }
        else if (type === 'password')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'checkbox')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'radio')
        {
            dataResults[field.fieldName] = e.target.value;
            
            if(field.fieldName == 'userRole'){
                this.setLimitAccess(e.target.value);
            }
        }
        else
        {
            dataResults[field.fieldName] = e.target.value;
        }

        if (typeof(field) !== 'undefined')
        {
            if (typeof(field.callBackFunction) !== "undefined")
            {
                this.props[field.callBackFunction](tmpValue);
            }
        }

        this.setState({dataResults: dataResults});
    };

    onButtonCancel = () => {
        for (const checkbox of this.selectedCheckboxes) {
            if (this.selectedCheckboxes.has(checkbox)) {
                this.selectedCheckboxes.delete(checkbox);
            }
        }
        this.props.onCancel();
    };
    handleCheckboxChange = (name, label, isChecked) => {
            if(name == 'cities' && label=="all_city"){
                let type = (isChecked)?'check-all':'uncheck-all';
                this.checkAllCity(type);
            }
            
            if (typeof(this.selectedCheckboxes[name]) === 'undefined') this.selectedCheckboxes[name] = new Set();
            if (this.selectedCheckboxes[name].has(label)) {
                this.selectedCheckboxes[name].delete(label);
            } else {
                this.selectedCheckboxes[name].add(label);
            }

            if(name == 'cities' && label!="all_city"){

                if(this.selectedCheckboxes["cities"].size > this.props.cities.length){
                    if(!this.selectedCheckboxes["cities"].has("all_city")){
                        this.selectedCheckboxes["cities"].add("all_city");
                        }
                } else if(this.selectedCheckboxes["cities"].size == this.props.cities.length)
                {
                    if(!this.selectedCheckboxes["cities"].has("all_city")){
                    this.selectedCheckboxes["cities"].add("all_city");
                    }
                    else {
                        this.selectedCheckboxes["cities"].delete("all_city");
                    }
                 }
                 else{
                    this.selectedCheckboxes["cities"].delete("all_city");
                }
            }

            let checkedBoxes = ',';
            for (const checkbox of this.selectedCheckboxes[name]) {
                checkedBoxes += checkbox + ",";
            }
            

            this.setState({
                dataResults: {...this.state.dataResults, [name]: checkedBoxes}
            })

            
        
    };
    changeValue = (name, value) => {
        let dataResults = this.state.dataResults;
        dataResults[name] = value;
        this.setState({dataResults: dataResults});
    };
    render() {
        let dataFields = this.state.dataFields;
        if (dataFields.length === 0) {
            dataFields = this.props.dataFields;
        }

        const { dataErrors, settings } = this.props;
        const { loading, dataResults } = this.state;

        let {  tableName, languageTable } = this.props;
        let pageTitle;
        let listTitle;
        let listSubTitle;
        let addTitle;
        let addSubTitle;
        let editTitle;
        let editSubTitle;
        let languageCode;
        let languageOutput;
        let authRole;
        let authTerritory;
        let authCities;
        let authLimitAccess;
        let incrUid;
        if (this.props.auth) {
            if (typeof(languageTable) === 'undefined' || languageTable === '') {
                languageTable = tableName;
            }
            if (this.props.auth.user.languageCode) {
                languageCode = this.props.auth.user.languageCode;
            } else if (this.props.auth.user.user.languageCode) {
                languageCode = this.props.auth.user.user.languageCode;
            }

            incrUid = this.props.auth.user.incr_uid;
            
            if (this.props.auth.user.userRole) {
                authRole = this.props.auth.user.userRole;
            }
            if (this.props.auth.user.territory && authRole == 2) {
                authTerritory = this.props.auth.user.territory;
                dataResults.territory = authTerritory; 
            }
            if (this.props.auth.user.cities && authRole == 2) {
                let citiesIndex = dataFields.findIndex((field) => {
                    return (field.fieldName === 'cities');
                });
                
                if(citiesIndex != -1){
                    dataFields[citiesIndex]["valueOptions"] = [{ name: 'All', value: 'all_city', id: 'all_city' },...this.props.auth.user.cities];
                }
                if(incrUid == dataResults.id){
                    dataFields[citiesIndex]["hideCheckbox"] = true;
                }else{
                    dataFields[citiesIndex]["hideCheckbox"] = false;
                }
            }
            
            if (this.props.auth.user.limitAccess && authRole == 2) {
                authLimitAccess = this.props.auth.user.limitAccess.split(",");
                let newValueOptions = [];
                authLimitAccess.forEach((options, index) => {
                    if (options !== '' && typeof(options) !== 'undefined' && options !== undefined) {
                        newValueOptions.push({
                            name: options.split('-').join(' '),
                            value: options.toString(),
                            id: options.toString()
                        });
                    }
                });
                let limitAccessIndex = dataFields.findIndex((field) => {
                    return (field.fieldName === 'limitAccess');
                });
                
                if(limitAccessIndex != -1){
                    dataFields[limitAccessIndex]["valueOptions"] = newValueOptions;
                }
                if(incrUid == dataResults.id){
                    dataFields[limitAccessIndex]["hideCheckbox"] = true;
                }else{
                    dataFields[limitAccessIndex]["hideCheckbox"] = false;
                }
            }

            languageOutput = languageText[languageCode].components[languageTable];
            pageTitle = languageOutput.pageTitle;
            listTitle = languageOutput.listTitle;
            listSubTitle = languageOutput.listSubTitle;
            addTitle = languageOutput.addTitle;
            addSubTitle = languageOutput.addSubTitle;
            editTitle = languageOutput.editTitle;
            editSubTitle = languageOutput.editSubTitle;
        }

        let territoryIndex = dataFields.findIndex((field) => {
            return (field.fieldName === 'territory');
        });

        let citiesIndex = dataFields.findIndex((field) => {
            return (field.fieldName === 'cities');
        });

        if(dataResults.userRole == "1"){
            if(territoryIndex != -1){ dataFields[territoryIndex]["required"] = false; }
            if(citiesIndex != -1){ dataFields[citiesIndex]["required"] = false; }
        }else{
            if(territoryIndex != -1){ dataFields[territoryIndex]["required"] = true; }
            if(citiesIndex != -1){ dataFields[citiesIndex]["required"] = true; }
        }

        if (loading === true)
        {
            return (<Loader/>);
        }
        else
        {
            if (settings.showCard === false)
            {
                return (<>
                    <h5>{listTitle}</h5>
                    <h6 className="card-subtitle text-muted">{listSubTitle}</h6>
                    <div className="container">
                        <DataButtons {...this.props} dataResults={dataResults} onButtonCancel={this.onButtonCancel} />
                        <div className="row align-items-end">
                            <div className="col-12 py-4">Record Id: {dataResults.recordId}</div>
                            {dataFields.map((field, index) => {
                                if (typeof(languageOutput.fields[field.fieldName]) === "undefined") {
                                    console.log("Field not found in language file - Field Name: ", field.fieldName, languageTable, languageCode);
                                    return null;
                                } else {
                                    let label = languageOutput.fields[field.fieldName].displayName;
                                    let placeholder = languageOutput.fields[field.fieldName].placeholder;
                                    return (<DataFields
                                        key={index}
                                        id={dataResults.id}
                                        field={field}
                                        fieldType={field.fieldType}
                                        fieldName={field.fieldName}
                                        fieldLabel={label}
                                        fieldPlaceHolder={placeholder}
                                        tableName={this.props.tableName}
                                        value={dataResults[field.fieldName]}
                                        error={dataErrors[field.fieldName]}
                                        onChange={this.onChange}
                                        changeValue={this.changeValue}
                                        handleCheckboxChange={this.handleCheckboxChange}
                                    />);
                                }
                            })}
                        </div>
                        {typeof(dataResults.addressLatitude) !== 'undefined' && dataResults.addressLatitude !== '' && dataResults.addressLongitude !== '' && <div className="row">
                            <AdddressGoogleMap
                                lat={dataResults.addressLatitude}
                                lng={dataResults.addressLongitude}
                                radius={dataResults.addressRadius}
                            />
                            <div className="col-12 small">
                                Latitude: {dataResults.addressLatitude},
                                Longitude: {dataResults.addressLongitude},
                                Address Mapped: {dataResults.address}
                            </div>
                        </div>}
                        <DataButtons {...this.props} dataResults={dataResults} onButtonCancel={this.onButtonCancel} />
                    </div>
                </>);
            }
            else
            {
                return (<Card>
                    <CardHeader>
                        <CardTitle tag="h5">{listTitle}</CardTitle>
                        <h6 className="card-subtitle text-muted">{listSubTitle}</h6>
                        <DataButtons {...this.props} dataResults={dataResults} onButtonCancel={this.onButtonCancel} />
                    </CardHeader>
                    <CardBody>
                        <div className="container">
                            <div className="row align-items-end">
                                <div className="col-12 py-4">Record Id: {dataResults.recordId}</div>
                                {dataFields.map((field, index) => {
                                    if (typeof(languageOutput.fields[field.fieldName]) === "undefined") {
                                        console.log("Field not found in language file - Field Name: ", field.fieldName, languageTable, languageCode, languageOutput);
                                        return null;
                                    } else {
                                        if(authRole == "2" && field.fieldName == 'territory'){
                                            return (<>
                                                <div className="col-12 col-sm-6">
                                                    <div className="container p-0">
                                                        <div className="form-group row border-bottom pb-2">
                                                            <label className="col-sm-12" htmlFor="territory">Selected State: <h3>{authTerritory}</h3></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>); 
                                        }else{
                                            let label = languageOutput.fields[field.fieldName].displayName;
                                            let placeholder = languageOutput.fields[field.fieldName].placeholder;
                                            return (<DataFields
                                                key={index}
                                                id={dataResults.id}
                                                field={field}
                                                fieldType={field.fieldType}
                                                fieldName={field.fieldName}
                                                fieldLabel={label}
                                                fieldPlaceHolder={placeholder}
                                                tableName={this.props.tableName}
                                                value={dataResults[field.fieldName]}
                                                error={dataErrors[field.fieldName]}
                                                onChange={this.onChange}
                                                changeValue={this.changeValue}
                                                handleCheckboxChange={this.handleCheckboxChange}
                                            />);
                                        }
                                    }
                                })}
                            </div>
                            {typeof(dataResults.addressLatitude) !== 'undefined' && dataResults.addressLatitude !== '' && dataResults.addressLongitude !== '' && <div className="row">
                                <AdddressGoogleMap
                                    lat={dataResults.addressLatitude}
                                    lng={dataResults.addressLongitude}
                                    radius={dataResults.addressRadius}
                                />
                                <div className="col-12 small">
                                    Latitude: {dataResults.addressLatitude},
                                    Longitude: {dataResults.addressLongitude},
                                    Address Mapped: {dataResults.address}
                                </div>
                            </div>}
                        </div>
                    </CardBody>
                    <CardFooter>
                        <DataButtons {...this.props} dataResults={dataResults} nButtonCancel={this.onButtonCancel} />
                    </CardFooter>
                </Card>);
            }
        }
    }
}


class DataFields extends Component {
    render() {
        const { field, fieldName, fieldType, fieldLabel, handleCheckboxChange, fieldPlaceHolder, value, onChange, changeValue, error, tableName, id } = this.props;
        return (
            <FormInput
                id={id}
                field={field}
                type={fieldType}
                name={fieldName}
                defaultValue={field.defaultValue}
                dropdown={field.dropdownData}
                valueOptions={field.valueOptions}
                checkboxData={field.checkboxData}
                colSize={field.colSize}
                value={value}
                changeValue={changeValue}
                tableName={tableName}
                onChange={onChange}
                error={error}
                label={fieldLabel}
                handleCheckboxChange={handleCheckboxChange}
                placeholder={fieldPlaceHolder}
            />
        );
    }
}
class DataButtons extends Component {
    render () {
        const {  dataResults } = this.props;
        const {  languageCode } = this.props.auth.user;
        return (<div className="container my-3">
            {this.props.singleEdit !== true && <div className="row">
                <div className="col-6">
                    <Button onClick={() => this.props.onSave(dataResults["id"], dataResults)} color="primary">
                        <SaveIcon size={18} />{' '}
                        {languageText[languageCode].buttons.save}
                    </Button>
                    {this.props.settings.showClone !== false && dataResults.id !== 0 && <Button onClick={() => this.props.onSave(0, dataResults)} color="secondary" className="ml-1">
                        <SaveIcon size={18} />{' '}
                        {languageText[languageCode].buttons.clone}
                    </Button>}
                </div>
                <div className="col-6 text-right">
                    <Button onClick={() => this.props.onCancel()} color="warning">
                        <CancelIcon size={18} />{' '}
                        {languageText[languageCode].buttons.cancel}
                    </Button>
                </div>
            </div>}
            {this.props.singleEdit === true && <div className="row">
                <div className="col-12">
                    <Button onClick={() => this.props.onSave(dataResults["id"], dataResults)} color="primary">
                        <SaveIcon size={18} />{' '}
                        {languageText[languageCode].buttons.save}
                    </Button>
                    {this.props.settings.showClone !== false && <Button onClick={() => this.props.onSave(0, dataResults)} color="primary" className="ml-1">
                        <SaveIcon size={18} />{' '}
                        {languageText[languageCode].buttons.clone}
                    </Button>}
                </div>
            </div>}
        </div>);
    }
}

const mapStateToProps = state => ({
    auth: state.auth.user,
    data: state.pages.data,
    states: state.pages.states,
    cities: state.pages.cities,
});

export default connect(mapStateToProps, {getUserRoles, getCities, getStates})(DataEdit);
