import {
    GET_DATA
} from './types';
import axios from 'axios';

import { ApiURL } from "../../config";
import {tokenConfig} from "./apiActions";

export const loadSubscriptions = () => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/subscriptions/loadSubscriptions`, {}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const loadSubscriptionsById = (listingId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/subscriptions/loadSubscriptionsById`, {listingId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const savePromotionalListing = (pageName, communityProviderId, promoListing, promoListingSdate, promoListingEdate) => async (dispatch, getState) => {
    try {
        console.log(pageName, communityProviderId, promoListing, promoListingSdate, promoListingEdate);
        let res = await axios.post(`${ApiURL}/subscriptions/savePromotionalFeaturedListing`, {pageName, communityProviderId, promoListing, promoListingSdate, promoListingEdate}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const cancelPromotionalListing = (pageName, communityProviderId, promoListing, promoListingSdate, promoListingEdate) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/subscriptions/cancelPromotionalFeaturedListing`, {pageName, communityProviderId, promoListing, promoListingSdate, promoListingEdate}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const savePromotionalJobBoard = (pageName, communityProviderId, promoListing, promoListingSdate, promoListingEdate) => async (dispatch, getState) => {
    try {
        console.log(pageName, communityProviderId, promoListing, promoListingSdate, promoListingEdate);
        let res = await axios.post(`${ApiURL}/subscriptions/savePromotionalFeaturedListing`, {pageName, communityProviderId, promoListing, promoListingSdate, promoListingEdate}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const cancelPromotionalJobBoard = (pageName, communityProviderId, promoListing, promoListingSdate, promoListingEdate) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/subscriptions/cancelPromotionalFeaturedListing`, {pageName, communityProviderId, promoListing, promoListingSdate, promoListingEdate}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
