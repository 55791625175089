import React, { Component, Fragment} from "react";
import { Button, Card, CardHeader, CardTitle } from "reactstrap";
import { Save as SaveIcon, XCircle as CancelIcon } from "react-feather";
import {connect} from "react-redux";
import CardBody from "reactstrap/es/CardBody";
import CardFooter from "reactstrap/es/CardFooter";
import FormInput from "../components/form/FormInput";
import Loader from "../components/layout/Loader";
import AdddressGoogleMap from "../components/google-maps/AddressGoogleMap";
import {languages, languageText} from "../config";
import { getUserRoles } from "../client/actions/apiActions"
import { saveProviderTypes, saveCommunityTypes, getCustomRecord, customSaveRecord, deleteListing, refreshStats, sendInviteUserToTeam } from "../client/actions/apiClientActions"
import { loadSubscriptionsById, savePromotionalListing, cancelPromotionalListing, savePromotionalJobBoard, cancelPromotionalJobBoard } from "../client/actions/apiSubscriptionActions";
import {confirmAlert} from "react-confirm-alert";
import { ModalWindow } from "./modal/ModalWindow";
import 'react-confirm-alert/src/react-confirm-alert.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
class DataEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataResults: [],
            metaData: [],
            dataTypes: [],
            dataErrors: [],
            tabName: '',
            listingId: 0,
            successMessage: '',

            analytics: [],
            promotionalListingFeatured: {
                startDate: null,
                endDate: null,
                error: '',
                isValid: false,
                promoListing: false
            },
            promotionalListingJobBoard: {
                amount: '',
                duration: '',
                startDate: null,
                error: '',
                isValid: false,
                promoListing: false
            },

            generalDataFields: [
                {
                    displayName: "Community Name",
                    fieldName: "comm_name",
                    fieldType: "text",
                    colSize: 4,
                    required: true,
                    requiredMessage: "Community Name is required"
                },
                {
                    displayName: "Business Type",
                    fieldName: "businessType",
                    fieldType: "radio",
                    colSize: 3,
                    required: true,
                    requiredMessage: "Business Type is required",
                    valueOptions: [
                        {
                            id: '0',
                            name: 'Community',
                            value: '0'
                        },
                        {
                            id: '1',
                            name: 'Provider',
                            value: '1'
                        },
                    ]
                },
                {
                    displayName: "Address",
                    fieldName: "address",
                    fieldType: "text",
                    colSize: 4,
                    required: true,
                    requiredMessage: "Address is required"
                },
                {
                    displayName: "City",
                    fieldName: "city",
                    fieldType: "text",
                    colSize: 3,
                    required: true,
                    requiredMessage: "City is required"
                },
                {
                    displayName: "State",
                    fieldName: "state",
                    fieldType: "state",
                    colSize: 3,
                    required: true,
                    requiredMessage: "State is required"
                },
                {
                    displayName: "Zip Code",
                    fieldName: "zip_code",
                    fieldType: "text",
                    colSize: 3,
                    required: true,
                    requiredMessage: "State is required"
                },
                {
                    displayName: "County",
                    fieldName: "county",
                    fieldType: "text",
                    colSize: 3,
                    required: true,
                    requiredMessage: "County is required"
                },
                {
                    displayName: "Latitude",
                    fieldName: "lat",
                    fieldType: "text",
                    colSize: 2,
                    required: true,
                    requiredMessage: "Latitude is required"
                },
                {
                    displayName: "Longitude",
                    fieldName: "lng",
                    fieldType: "text",
                    colSize: 2,
                    required: true,
                    requiredMessage: "Longitude is required"
                },
            ],
            additionalDataFields: [
                {
                    displayName: "DBA",
                    fieldName: "dba",
                    fieldType: "text",
                    colSize: 3,
                    required: false,
                    requiredMessage: ""
                },
                {
                    displayName: "License",
                    fieldName: "license",
                    fieldType: "text",
                    colSize: 3,
                    required: false,
                    requiredMessage: ""
                },
                {
                    displayName: "Corporation Type",
                    fieldName: "corporationType",
                    fieldType: "radio",
                    colSize: 3,
                    required: false,
                    requiredMessage: "",
                    valueOptions: [
                        {
                            id: 'Public',
                            name: 'Publicly Traded',
                            value: 'Public'
                        },
                        {
                            id: 'Private',
                            name: 'Private',
                            value: 'Private'
                        },
                    ]
                },
                {
                    displayName: "Status",
                    fieldName: "status",
                    fieldType: "radio",
                    colSize: 3,
                    required: false,
                    requiredMessage: "",
                    valueOptions: [
                        {
                            id: 'Profit',
                            name: 'Profit',
                            value: 'Profit'
                        },
                        {
                            id: 'Non-Profit',
                            name: 'Non-Profit',
                            value: 'Non-Profit'
                        },
                    ]
                },
                {
                    displayName: "Year Founded",
                    fieldName: "year_founded",
                    fieldType: "text",
                    colSize: 3,
                    required: false,
                    requiredMessage: ""
                },
                {
                    displayName: "Primarily Phone",
                    fieldName: "primarily_phone",
                    fieldType: "text",
                    colSize: 3,
                    required: false,
                    requiredMessage: ""
                },
                {
                    displayName: "Cell Phone",
                    fieldName: "cell_phone",
                    fieldType: "text",
                    colSize: 3,
                    required: false,
                    requiredMessage: ""
                },
                {
                    displayName: "Fax Phone",
                    fieldName: "fax_phone",
                    fieldType: "text",
                    colSize: 3,
                    required: false,
                    requiredMessage: ""
                },
                {
                    displayName: "Company Website",
                    fieldName: "website",
                    fieldType: "text",
                    colSize: 3,
                    required: false,
                    requiredMessage: ""
                },
                {
                    displayName: "Number Of Units",
                    fieldName: "numberOfUnits",
                    fieldType: "text",
                    colSize: 3,
                    required: false,
                    requiredMessage: ""
                },
                {
                    displayName: "External Website (LinkedIn)",
                    fieldName: "external_website_1",
                    fieldType: "text",
                    colSize: 3,
                    required: false,
                    requiredMessage: ""
                },
                {
                    displayName: "External Website (Youtube)",
                    fieldName: "external_website_2",
                    fieldType: "text",
                    colSize: 3,
                    required: false,
                    requiredMessage: ""
                },
                {
                    displayName: "External Website (Twitter)",
                    fieldName: "external_website_3",
                    fieldType: "text",
                    colSize: 3,
                    required: false,
                    requiredMessage: ""
                },
                {
                    displayName: "External Website (Facebook)",
                    fieldName: "external_website_4",
                    fieldType: "text",
                    colSize: 3,
                    required: false,
                    requiredMessage: ""
                },
                {
                    displayName: "External Website (Yelp)",
                    fieldName: "external_website_5",
                    fieldType: "text",
                    colSize: 3,
                    required: false,
                    requiredMessage: ""
                },
                {
                    displayName: "External Website (Google Plus)",
                    fieldName: "external_website_6",
                    fieldType: "text",
                    colSize: 3,
                    required: false,
                    requiredMessage: ""
                },
                {
                    displayName: "External Website (Instagram)",
                    fieldName: "external_website_7",
                    fieldType: "text",
                    colSize: 3,
                    required: false,
                    requiredMessage: ""
                },
                {
                    displayName: "Company Logo",
                    fieldName: "company_logo",
                    fieldType: "image",
                    colSize: 1,
                    required: false,
                    requiredMessage: ""
                },
                {
                    displayName: "Company Overview",
                    fieldName: "company_overview",
                    fieldType: "textarea",
                    colSize: 1,
                    required: false,
                    requiredMessage: ""
                },
            ],
            featuredSubscriptions: [],
            sponsorSubscriptions: [],
            modalData:{
                showModalWindow: false
            }
        }
        this.selectedCheckboxes = new Set();
    }
    componentDidMount() {
        this.setState({
            dataResults : this.props.fieldDataResults
        });
        this.changeTab('listing');
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            let dataInfo = this.props.data;
            let metaData = dataInfo.metaData ? dataInfo.metaData : '';
            let dataTypes = dataInfo.dataTypes ? dataInfo.dataTypes : '';

            let community_prices;
            let community_type;
            let price_type;
            let community_amenities;
            let community_specialties;

            if (dataTypes && dataTypes.length >0) {
                price_type = dataTypes.find(value => value.detail_type === "price_type") ? dataTypes.find(value => value.detail_type === "price_type") : [];
                community_type = dataTypes.find(value => value.detail_type === "community_type") ? dataTypes.find(value => value.detail_type === "community_type") : [];
                community_amenities = dataTypes.find(value => value.detail_type === "community_anamnetis") ? dataTypes.find(value => value.detail_type === "community_anamnetis") : [];
                community_specialties = dataTypes.find(value => value.detail_type === "community_specility") ? dataTypes.find(value => value.detail_type === "community_specility") : [];

                if (community_amenities.value) this.updateCheckboxes("communityAmenities", community_amenities.value);
                if (community_specialties.value) this.updateCheckboxes("communitySpecialties", community_specialties.value);
                if (community_type.value) this.updateCheckboxes("communityTypes", community_type.value);
                if (price_type.value) this.updateCheckboxes("communityPricing", JSON.stringify(JSON.parse(price_type.value)?.pricing));
                if (price_type.value) this.updateCheckboxes("communityPricing", JSON.stringify(JSON.parse(price_type.value)?.type));
            }
            if (dataInfo.dataType === 'getCustomRecord') {
                this.setState({
                    dataResults : dataInfo.data,
                    listingId: dataInfo.community_provider_id,
                    dataTypes : dataTypes,
                    metaData: metaData,
                    loading: false,
                    tabLoaded: false
                });
            }
            if (dataInfo.dataType === 'getCustomRecord' &&  dataInfo.tabName == 'listing') {
                console.log('getCustomRecord', dataInfo.data);
                let promotionalListingFeatured = this.state.promotionalListingFeatured;
                promotionalListingFeatured.startDate = (dataInfo.data.promo_listing_sdate)?new Date(dataInfo.data.promo_listing_sdate):null;
                promotionalListingFeatured.endDate = (dataInfo.data.promo_listing_edate)?new Date(dataInfo.data.promo_listing_edate):null;
                promotionalListingFeatured.promoListing = dataInfo.data.promo_listing;
                this.setState({             
                    promotionalListingFeatured
                });
            }
            if (dataInfo.dataType === 'deleteListing') {
                this.props.closeFunction();
            }
            if (dataInfo.pageName === "loadSubscriptions") {
                this.setState({
                    featuredSubscriptions: this.props.data.featuredSubscriptions,
                    sponsorSubscriptions: this.props.data.sponsorSubscriptions
                })
            }
            if (dataInfo.pageName === "refreshStats") {
                this.setState({
                    analytics: this.props.data.analytics
                })
            }
            if (dataInfo.pageName === "sendInviteUserToTeam") {
                this.setState({
                    successMessage : ''
                })
                let modalData = this.state.modalData;
                if (dataInfo.emailStatus !== true) {
                    modalData["errorMessage"] = dataInfo.emailStatus;
                    modalData["successMessage"] = '';
                } else if (dataInfo.success === false) {
                    modalData["errorMessage"] = dataInfo.message;
                    modalData["successMessage"] = '';
                } else if (dataInfo.success === true) {
                    modalData["showModalWindow"] = false;
                    modalData["errorMessage"] = '';
                    this.setState({
                        successMessage : dataInfo.message
                    })
                    this.props.getCustomRecord(this.state.tabName, this.props.fieldDataResults.community_provider_id);
                }
                this.setState({
                    modalData
                })
            }
            if (dataInfo.pageName === "saveCommunityTypes") {
                this.props.showToastr("success", "Save Successful", "Types saved!");
            }
            if (dataInfo.pageName === "saveProviderTypes") {
                this.props.showToastr("success", "Save Successful", "Types saved!");
            }
            if (dataInfo.pageName === "customSaveRecord") {
                this.props.showToastr("success", "Save Successful", "Record saved!");
            }
            if (dataInfo.pageName === "promoListingSave") {
                if(dataInfo.success == true){
                    let promotionalListingFeatured = this.state.promotionalListingFeatured;
                    promotionalListingFeatured.promoListing = true;
                    this.setState({
                        promotionalListingFeatured
                    });
                }
            }
            if (dataInfo.pageName === "promoListingCancel") {
                console.log('promoListingCancel', this.state.promotionalListingFeatured);
                let promotionalListingFeatured = this.state.promotionalListingFeatured;
                promotionalListingFeatured.startDate = null;
                promotionalListingFeatured.endDate = null;
                promotionalListingFeatured.promoListing = false;

                this.setState({
                    promotionalListingFeatured
                })
            }
        }
    }
    loadSubscriptions = () => {
        this.props.loadSubscriptionsById(this.state.listingId);
    }

    onChange = (type, e, field) => {
        let dataResults = this.state.dataResults;
        let tmpValue = '';
        if (type === 'text')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'phone')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'email')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'textarea')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'toggle')
        {
            if (e.target.checked === true) dataResults[field.fieldName] = '1';
            if (e.target.checked === false) dataResults[field.fieldName] = '0';
        }
        else if (type === 'image')
        {
            dataResults[field.fieldName] = e;
        }
        else if (type === 'editor')
        {
            dataResults[field.fieldName] = e.target.getContent();
        }
        else if (type === 'select' || type === 'dropdown')
        {
            dataResults[e.target.name] = e.target.value;
        }
        else if (type === 'password')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'checkbox')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'radio')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else
        {
            dataResults[field.fieldName] = e.target.value;
        }

        if (typeof(field) !== 'undefined')
        {
            if (typeof(field.callBackFunction) !== "undefined")
            {
                this.props[field.callBackFunction](tmpValue);
            }
        }

        this.setState({dataResults: dataResults});
    };

    onButtonCancel = () => {
        for (const checkbox of this.selectedCheckboxes) {
            if (this.selectedCheckboxes.has(checkbox)) {
                this.selectedCheckboxes.delete(checkbox);
            }
        }
        this.props.onCancel();
    };
    updateCheckboxes = (name, values) => {
        let jsonValues = [];
        if (values && values !== "Array") {
            jsonValues = JSON.parse(values);
            if (typeof(this.selectedCheckboxes[name]) === 'undefined') {
                this.selectedCheckboxes[name] = new Set();
            }

            if (jsonValues.length > 0 && typeof(jsonValues) === "object") {
                jsonValues.forEach((value) => {
                    this.selectedCheckboxes[name].add(value);
                });
            }
        }
    }
    handleCheckboxChange = (name, label) => {
        
        if (typeof(this.selectedCheckboxes[name]) === 'undefined') {
            this.selectedCheckboxes[name] = new Set();
        }
        if (this.selectedCheckboxes[name].has(parseInt(label))) {
            this.selectedCheckboxes[name].delete(parseInt(label));
        } else {
            this.selectedCheckboxes[name].add(parseInt(label));
        }

        let checkedBoxes = ',';
        for (const checkbox of this.selectedCheckboxes[name]) {
            checkedBoxes += checkbox + ",";
        }

        let businessType = this.props.fieldDataResults.businessType;
        if (businessType === '0') {
            this.setState({
                dataResults: {...this.state.dataResults, [name]: checkedBoxes}
            })
        }
        if (businessType === '1') {
            let newData = JSON.parse(this.state.dataResults[name]);
            if (typeof(newData) === 'string') newData = [];
            if (newData === null) { newData = []; }
            let data = parseInt(label);
            if (newData.includes(data) === false) {
                newData.push(data);
            } else {
                const index = newData.indexOf(data);
                if (index > -1) {
                    newData.splice(index, 1); // 2nd parameter means remove one item only
                }
            }

            this.setState({
                dataResults: {...this.state.dataResults, [name]: JSON.stringify(newData)}
            });
        }
    };

    changeValue = (name, value) => {
        let dataResults = this.state.dataResults;
        dataResults[name] = value;
        this.setState({dataResults: dataResults});
    };
    changeTab = (tabName) => {
        if (tabName === 'stats') {
            this.refreshStats();
        } else if (tabName !== 'delete' && tabName !== 'subscriptions') {
            this.props.getCustomRecord(tabName, this.props.fieldDataResults.community_provider_id);
        } else if (tabName === 'subscriptions') {
            this.props.loadSubscriptionsById(this.props.fieldDataResults.community_provider_id);
        }
        this.setState({
            tabName,
            loading: true
        });
    }

    onSave = () => {
        let data = {};
        let fields = {};
        if (this.state.tabName === 'listing') {
            fields['community_provider']={};
            fields['company']={};
            this.state.generalDataFields.map((field, index) => {
                let value = this.state.dataResults[field.fieldName];
                data[field.fieldName] = value;
                fields['community_provider'][field.fieldName] = value;
            });
            this.state.additionalDataFields.map((field, index) => {
                let value = this.state.dataResults[field.fieldName];
                data[field.fieldName] = value;
                fields['company'][field.fieldName] = value;
            });
            this.props.customSaveRecord(this.state.tabName, this.props.fieldDataResults.community_provider_id, data, fields);
        }

        if (this.state.tabName === 'types') {
            let businessType = this.props.fieldDataResults.businessType;
            let listingId = this.state.dataResults.community_provider_id;
            if (businessType === '0') {
                let communityInsuranceTypes = [];
                if (this.selectedCheckboxes["communityInsuranceTypes"]) {
                    for (const value of this.selectedCheckboxes["communityInsuranceTypes"]) {
                        communityInsuranceTypes.push(parseInt(value));
                    }
                }

                let communityPricing = [];
                if (this.selectedCheckboxes["communityPricing"]) {
                    for (const value of this.selectedCheckboxes["communityPricing"]) {
                        communityPricing.push(parseInt(value));
                    }
                }

                let communitySpecialties = [];
                if (this.selectedCheckboxes["communitySpecialties"]) {
                    for (const value of this.selectedCheckboxes["communitySpecialties"]) {
                        communitySpecialties.push(parseInt(value));
                    }
                }

                let communityTypes = [];
                if (this.selectedCheckboxes["communityTypes"]) {
                    for (const value of this.selectedCheckboxes["communityTypes"]) {
                        communityTypes.push(parseInt(value));
                    }
                }

                let communityAmenities = [];
                if (this.selectedCheckboxes["communityAmenities"]) {
                    for (const value of this.selectedCheckboxes["communityAmenities"]) {
                        communityAmenities.push(parseInt(value));
                    }
                }

                let types = {
                    insurance_types: JSON.stringify(communityInsuranceTypes),
                    pricing: JSON.stringify(communityPricing),
                    community_specility: JSON.stringify(communitySpecialties),
                    community_type: JSON.stringify(communityTypes),
                    community_anamnetis: JSON.stringify(communityAmenities)
                }

                this.props.saveCommunityTypes(listingId, types);
            }
            if (businessType === '1') {
                let providerTypes = this.state.dataResults.listingType;
                let providerSubTypes = this.state.dataResults.listingSubType;
                this.props.saveProviderTypes(listingId, JSON.parse(providerTypes), JSON.parse(providerSubTypes));
            }


        }
    }
    onCancel = () => {
        this.props.closeFunction();
    }

    deleteListingConfirm = () => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you wish to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.deleteListing()
                },
                {
                    label: 'No'
                }
            ]
        });
    }
    deleteListing = () => {
        this.props.deleteListing(this.state.dataResults.community_provider_id);
    }

    // CLOSE MODAL WINDOW
    closeModalWindow = () => {
        let modalData = {
            showModalWindow: false
        }
        this.setState({
            modalData
        });
    };

    modalFunctionController = (functionToRun, data) => {
        if (functionToRun === 'sendInviteUserToTeam') {
            this.sendInviteUser(data);
        }

    }
    sendInviteUser = (data) => {
        this.props.sendInviteUserToTeam(
            this.state.dataResults.teamId,
            this.props.fieldDataResults.community_provider_id,
            data.userRole,
            data.emailAddress
        );
    }

    showInviteUser = () => {
        let modalData = {
            showModalWindow: true,
            type: 'inviteUser',
            title: "Invite a User to this Team"
        }
        this.setState({
            modalData
        });
    }

    viewSubscription = () => {
        alert("not implemented");
    }

    refreshStats = () => {
        this.props.refreshStats(this.props.fieldDataResults.community_provider_id);
    }

    saveFeaturedPromotional = ()=>{
        
        let newObj = this.state.promotionalListingFeatured;
        newObj.isValid = true;
        if(newObj.startDate == ''){
            newObj.isValid = false;
            //newObj.error = 'Start date is required.';
            newObj.error = 'All fields required.';
        }
        if(newObj.endDate == ''){
            newObj.isValid = false;
            //newObj.error = 'End date is required.';
            newObj.error = 'All fields required.';
        }
        this.setState({promotionalListingFeatured:newObj});
        if(newObj.isValid){
            this.props.savePromotionalListing(
                'promoListingSave',
                this.props.fieldDataResults.community_provider_id,
                true,
                moment(newObj.startDate).local().format('YYYY-MM-DD'),
                moment(newObj.endDate).local().format('YYYY-MM-DD')
            );
        }
    }

    cancelFeaturedPromotional = ()=>{
        if(window.confirm('Are you sure?')){
            this.props.cancelPromotionalListing(
                'promoListingCancel',
                this.props.fieldDataResults.community_provider_id,
                true,
                null,
                null
            );
        }
    }

    saveJobBoardPromotional = ()=>{
        
        let newObj = this.state.promotionalListingJobBoard;
        newObj.isValid = true;
        if(newObj.startDate == ''){
            newObj.isValid = false;
            //newObj.error = 'Start date is required.';
            newObj.error = 'All fields required.';
        }
        if(newObj.amount == ''){
            newObj.isValid = false;
            //newObj.error = 'Amount is required.';
            newObj.error = 'All fields required.';
        }
        if(newObj.duration == ''){
            newObj.isValid = false;
            //newObj.error = 'Duration is required.';
            newObj.error = 'All fields required.';
        }
        this.setState({promotionalListingJobBoard:newObj});
        if(newObj.isValid){
            this.props.savePromotionalJobBoard(
                'promoJobBoardSave',
                this.props.fieldDataResults.community_provider_id,
                true,
                newObj.amount,
                newObj.duration,
                moment(newObj.startDate).local().format('YYYY-MM-DD')
            );
        }
    }

    cancelJobBoardPromotional = ()=>{
        if(window.confirm('Are you sure?')){
            this.props.cancelPromotionalJobBoard(
                'promoJobBoardCancel',
                this.props.fieldDataResults.community_provider_id
            );
        }
    }

    onChangePromotionalListingFeatured = (date, name)=>{
        let promotionalListingFeatured = this.state.promotionalListingFeatured;
        promotionalListingFeatured[name] = date;
        this.setState({promotionalListingFeatured: promotionalListingFeatured});
    }

    onChangePromotionalListingJobBoard = (event, name)=>{
        let promotionalListingJobBoard = this.state.promotionalListingJobBoard;
        promotionalListingJobBoard[name] = event.target.value;
        this.setState({promotionalListingJobBoard: promotionalListingJobBoard});
    }

    render() {

        const { dataErrors, settings } = this.props;
        const { loading, dataResults, tabName, generalDataFields, successMessage, additionalDataFields, metaData, dataTypes, featuredSubscriptions, sponsorSubscriptions, modalData, promotionalListingFeatured, promotionalListingJobBoard } = this.state;
        let {  tableName, languageTable } = this.props;
        let editTitle;
        let editSubTitle;
        let languageCode;
        let languageOutput;
        let community_prices;
        let community_type;
        let community_amenities;
        let community_specialties;
        let provider_type = [];
        let provider_subtype = [];
        let price_type;
        let limitAccess = [];
        let authRole;
        if (this.props.auth) {
            if (typeof(languageTable) === 'undefined' || languageTable === '') {
                languageTable = tableName;
            }
            if (this.props.auth.user.languageCode) {
                languageCode = this.props.auth.user.languageCode;
            } else if (this.props.auth.user.user.languageCode) {
                languageCode = this.props.auth.user.user.languageCode;
            }

            authRole = this.props.auth.user.userRole;
            if (this.props.auth.user.limitAccess && this.props.auth.user.userRole == 2) {
                let authLimitAccess = this.props.auth.user.limitAccess.split(",");
                
                authLimitAccess.forEach((options, index) => {
                    if (options !== '' && typeof(options) !== 'undefined' && options !== undefined) {
                        limitAccess.push(options.toString());
                    }
                });
            }
            languageOutput = languageText[languageCode].components[languageTable];
            editTitle = languageOutput.editTitle;
            editSubTitle = languageOutput.editSubTitle;
        }
        if (!loading && tabName === "types") {

            community_prices = dataTypes.find(value => value.detail_type === "community_prices") ? dataTypes.find(value => value.detail_type === "community_prices") : [];
            community_type = dataTypes.find(value => value.detail_type === "community_type") ? dataTypes.find(value => value.detail_type === "community_type") : [];
            community_amenities = dataTypes.find(value => value.detail_type === "community_anamnetis") ? dataTypes.find(value => value.detail_type === "community_anamnetis") : [];
            community_specialties = dataTypes.find(value => value.detail_type === "community_specility") ? dataTypes.find(value => value.detail_type === "community_specility") : [];
            price_type = dataTypes.find(value => value.detail_type === "price_type") ? dataTypes.find(value => value.detail_type === "price_type") : [];

            if (typeof(dataResults.listingType) !== 'undefined') {
                provider_type = JSON.parse(dataResults.listingType);
            }
            if (typeof(dataResults.listingSubType) !== 'undefined') {
                provider_subtype = JSON.parse(dataResults.listingSubType);
            }
        }

        let sponsorshipStatus = [];
        sponsorshipStatus[0] = 'Pending Approval';
        sponsorshipStatus[1] = 'Pending Provider Completion';
        sponsorshipStatus[2] = 'Sponsored';
        sponsorshipStatus[3] = 'Expired';
        sponsorshipStatus[4] = 'Paused';
        sponsorshipStatus[5] = 'Pending Acceptance';

        return (<Card>
            <CardHeader>
                <CardTitle tag="h5">{editTitle}</CardTitle>
                <h6 className="card-subtitle text-muted">{editSubTitle}</h6>
                <button className="btn btn-secondary" onClick={()=>this.onCancel()}>Back</button>
            </CardHeader>
            <CardBody>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation"><button onClick={() => this.changeTab('listing')} className={`nav-link ` + (tabName === 'listing' ? 'active bg-primary text-white' : '')}>Listing</button></li>
                    <li className="nav-item" role="presentation"><button onClick={() => this.changeTab('types')} className={`nav-link ` + (tabName === 'types' ? 'active bg-primary text-white' : '')}>Types</button></li>
                    <li className="nav-item" role="presentation"><button onClick={() => this.changeTab('incentives')} className={`nav-link ` + (tabName === 'incentives' ? 'active bg-primary text-white' : '')}>Incentives</button></li>
                    <li className="nav-item" role="presentation"><button onClick={() => this.changeTab('events')} className={`nav-link ` + (tabName === 'events' ? 'active bg-primary text-white' : '')}>Events</button></li>
                    <li className="nav-item" role="presentation"><button onClick={() => this.changeTab('contact')} className={`nav-link ` + (tabName === 'contact' ? 'active bg-primary text-white' : '')}>Contact</button></li>
                    <li className="nav-item" role="presentation"><button onClick={() => this.changeTab('subscriptions')} className={`nav-link ` + (tabName === 'subscriptions' ? 'active bg-primary text-white' : '')}>Subscriptions</button></li>
                    <li className="nav-item" role="presentation"><button onClick={() => this.changeTab('stats')} className={`nav-link ` + (tabName === 'stats' ? 'active bg-primary text-white' : '')}>Stats</button></li>
                    { (limitAccess.includes("Delete-Business") || authRole == 1) && 
                    <li className="nav-item" role="presentation"><button onClick={() => this.changeTab('delete')} className={`nav-link bg-danger text-white` + (tabName === 'delete' ? 'active bg-primary text-white' : '')}>Delete</button></li>
                    }
                    &nbsp; &nbsp; &nbsp; LISTING ID : {dataResults.community_provider_id !== '' && <>{dataResults.community_provider_id}</>} {dataResults.listingId !== '' && <>{dataResults.listingId}</>}
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className={`tab-pane fade border-left border-bottom border-right p-4 ` + (tabName === 'listing' ? 'show active' : '')} role="tabpanel">
                        {loading && <Loader />}
                        {!loading && tabName === "listing" && <>
                            <DataButtons onSave={this.onSave} />
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <h4 className="mt-4">General Information</h4>
                                    </div>
                                </div>
                                <div className="row align-items-end">
                                    {generalDataFields.map((field, index) => {
                                        let label = field.displayName;
                                        let placeholder = field.placeholder;
                                        return (<DataFields
                                            key={index}
                                            id={dataResults.id}
                                            field={field}
                                            fieldType={field.fieldType}
                                            fieldName={field.fieldName}
                                            fieldLabel={label}
                                            fieldPlaceHolder={placeholder}
                                            tableName={this.props.tableName}
                                            value={dataResults[field.fieldName]}
                                            onChange={this.onChange}
                                            changeValue={this.changeValue}
                                            handleCheckboxChange={this.handleCheckboxChange}
                                        />);
                                    })}
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <h4 className="mt-4">Additional Information</h4>
                                    </div>
                                </div>
                                <div className="row align-items-end">
                                    {additionalDataFields.map((field, index) => {
                                        let label = field.displayName;
                                        let placeholder = field.placeholder;
                                        return (<DataFields
                                            key={index}
                                            id={dataResults.id}
                                            field={field}
                                            fieldType={field.fieldType}
                                            fieldName={field.fieldName}
                                            fieldLabel={label}
                                            fieldPlaceHolder={placeholder}
                                            tableName={this.props.tableName}
                                            value={dataResults[field.fieldName]}
                                            onChange={this.onChange}
                                            changeValue={this.changeValue}
                                            handleCheckboxChange={this.handleCheckboxChange}
                                        />);
                                    })}
                                </div>

                            </div>
                            <DataButtons onSave={this.onSave} />
                        </>}
                    </div>
                    <div className={`tab-pane fade border-left border-bottom border-right p-4 ` + (tabName === 'types' ? 'show active' : '')} role="tabpanel">
                        {loading && <Loader />}
                        {!loading && tabName === "types" && dataResults.businessType === "0" && <>
                            <DataButtons onSave={this.onSave} /><br/>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="alert alert-success p-4">
                                            {successMessage}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row align-items-end">
                                    <DataFields
                                        id={0}
                                        field={{
                                            defaultValue: '',
                                            valueOptions: metaData.community_type,
                                            colSize: 1
                                        }}
                                        fieldType="checkbox"
                                        fieldLabel={"Community Types"}
                                        fieldName={"communityTypes"}
                                        value={community_type.value}
                                        onChange={this.onChange}
                                        changeValue={this.changeValue}
                                        handleCheckboxChange={this.handleCheckboxChange}
                                    />
                                </div>
                                <div className="row align-items-end">
                                    <DataFields
                                        id={0}
                                        field={{
                                            defaultValue: '',
                                            valueOptions: metaData.community_amenities,
                                            colSize: 1
                                        }}
                                        fieldType="checkbox"
                                        fieldLabel={"Amenities"}
                                        fieldName={"communityAmenities"}
                                        value={community_amenities.value}
                                        onChange={this.onChange}
                                        changeValue={this.changeValue}
                                        handleCheckboxChange={this.handleCheckboxChange}
                                    />
                                </div>
                                <div className="row align-items-end">
                                    <DataFields
                                        id={0}
                                        field={{
                                            defaultValue: '',
                                            valueOptions: metaData.community_specialties,
                                            colSize: 1
                                        }}
                                        fieldType="checkbox"
                                        fieldLabel={"Specialties"}
                                        fieldName={"communitySpecialties"}
                                        value={community_specialties.value}
                                        onChange={this.onChange}
                                        changeValue={this.changeValue}
                                        handleCheckboxChange={this.handleCheckboxChange}
                                    />
                                </div>
                                <div className="row align-items-end">
                                    <DataFields
                                        id={0}
                                        field={{
                                            defaultValue: '',
                                            valueOptions: metaData.pricing,
                                            colSize: 1
                                        }}
                                        fieldType="checkbox"
                                        fieldLabel={"Pricing"}
                                        fieldName={"communityPricing"}
                                        value={price_type.value}
                                        onChange={this.onChange}
                                        changeValue={this.changeValue}
                                        handleCheckboxChange={this.handleCheckboxChange}
                                    />
                                </div>
                                <div className="row align-items-end">
                                    <DataFields
                                        id={0}
                                        field={{
                                            defaultValue: '',
                                            valueOptions: metaData.price_type,
                                            colSize: 1
                                        }}
                                        fieldType="checkbox"
                                        fieldLabel={"Insurance Types"}
                                        fieldName={"communityInsuranceTypes"}
                                        value={price_type.value}
                                        onChange={this.onChange}
                                        changeValue={this.changeValue}
                                        handleCheckboxChange={this.handleCheckboxChange}
                                    />
                                </div>
                            </div>
                            <DataButtons onSave={this.onSave} />
                        </>}
                        {!loading && tabName === "types" && dataResults.businessType === "1" && <>
                            <DataButtons onSave={this.onSave} /><br/>
                            <div className="container">
                                <div className="row align-items-end">
                                    <DataFields
                                        id={0}
                                        field={{
                                            defaultValue: '',
                                            valueOptions: metaData.provider_type,
                                            colSize: 1
                                        }}
                                        fieldType="checkbox"
                                        fieldLabel={"Provider Types"}
                                        fieldName={"listingType"}
                                        value={provider_type}
                                        onChange={this.onChange}
                                        changeValue={this.changeValue}
                                        handleCheckboxChange={this.handleCheckboxChange}
                                    />
                                    <DataFields
                                        id={0}
                                        field={{
                                            defaultValue: '',
                                            valueOptions: metaData.sub_provider,
                                            colSize: 1
                                        }}
                                        fieldType="checkbox"
                                        fieldLabel={"Provider Sub Types"}
                                        fieldName={"listingSubType"}
                                        value={provider_subtype}
                                        onChange={this.onChange}
                                        changeValue={this.changeValue}
                                        handleCheckboxChange={this.handleCheckboxChange}
                                    />
                                </div>
                            </div>
                            <DataButtons onSave={this.onSave} />
                        </>}
                    </div>
                    <div className={`tab-pane fade border-left border-bottom border-right p-4 ` + (tabName === 'incentives' ? 'show active' : '')} role="tabpanel">
                        {loading && <Loader />}
                        {!loading && tabName === "incentives" && <>
                            <div className="container">
                                <div className="row py-1 bg-dark text-white">
                                    <div className="col-1">Image</div>
                                    <div className="col">Name</div>
                                    <div className="col-2">Start</div>
                                    <div className="col-2">End</div>
                                    <div className="col-1">Paused</div>
                                    <div className="col-1">Published</div>
                                    <div className="col-1">Viewed</div>
                                    <div className="col-1">Shared</div>
                                </div>
                                {dataResults && dataResults.map((incentive, index) => {
                                    return (
                                        <div className="row py-1" key={index}>
                                            <div className="col-1">{incentive.image && <img src={incentive.image} className="img-fluid rounded"/>}</div>
                                            <div className="col">{incentive.incentive_name}</div>
                                            <div className="col-2">{incentive.start_date}</div>
                                            <div className="col-2">{incentive.end_date}</div>
                                            <div className="col-1">{incentive.is_paused}</div>
                                            <div className="col-1">{incentive.published}</div>
                                            <div className="col-1">{incentive.viewed}</div>
                                            <div className="col-1">{incentive.shared}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </>}
                    </div>
                    <div className={`tab-pane fade border-left border-bottom border-right p-4 ` + (tabName === 'events' ? 'show active' : '')} role="tabpanel">
                        {loading && <Loader />}
                        {!loading && tabName === "events" && <>
                            <div className="container">
                                <div className="row py-1 bg-dark text-white">
                                    <div className="col-1">Image</div>
                                    <div className="col-3">Name</div>
                                    <div className="col-2">Date</div>
                                    <div className="col-2">Time</div>
                                    <div className="col-1">End Time</div>
                                    <div className="col-1">Viewed</div>
                                    <div className="col-1">Shared</div>
                                    <div className="col-1">RSVP</div>
                                </div>
                                {dataResults && dataResults.map((event, index) => {
                                    return (
                                        <div className="row py-1" key={index}>
                                            <div className="col-1">{event.event_image && <img src={event.event_image} className="img-fluid rounded"/>}</div>
                                            <div className="col-3">{event.event_name}</div>
                                            <div className="col-2">{event.event_date}</div>
                                            <div className="col-2">{event.time}</div>
                                            <div className="col-1">{event.end_time}</div>
                                            <div className="col-1">{event.viewed}</div>
                                            <div className="col-1">{event.shared}</div>
                                            <div className="col-1">{event.rsvped}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </>}
                    </div>
                    <div className={`tab-pane fade border-left border-bottom border-right p-4 ` + (tabName === 'contact' ? 'show active' : '')} role="tabpanel">
                        {loading && <Loader />}
                        {!loading && tabName === "contact" && <>
                            <div className="container">
                                {successMessage && <div className="row">
                                    <div className="col-12 ">
                                        <div className="alert alert-success p-3">
                                            {successMessage}
                                        </div>
                                    </div>
                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <h3>{dataResults.teamInfo.name}</h3>
                                        <button className="btn btn-sm btn-secondary" onClick={() => this.showInviteUser()}>Invite User</button>
                                    </div>
                                </div>
                                <div className="row py-2">
                                    <div className="col-4">
                                        Admin
                                    </div>
                                    <div className="col-8">
                                        {dataResults.userInfo.adminUserId.f_name} {" "}
                                        {dataResults.userInfo.adminUserId.l_name} - {" "}
                                        {dataResults.userInfo.adminUserId.email}
                                    </div>
                                </div>
                                <div className="row py-2">
                                    <div className="col-4">
                                        Regional
                                    </div>
                                    <div className="col-8">
                                        {dataResults.userInfo.regionalUserId.f_name} {" "}
                                        {dataResults.userInfo.regionalUserId.l_name} - {" "}
                                        {dataResults.userInfo.regionalUserId.email}
                                    </div>
                                </div>
                                <div className="row py-2">
                                    <div className="col-4">
                                        Sales Director
                                    </div>
                                    <div className="col-8">
                                        {dataResults.userInfo.salesUserId.f_name} {" "}
                                        {dataResults.userInfo.salesUserId.l_name} - {" "}
                                        {dataResults.userInfo.salesUserId.email}
                                    </div>
                                </div>
                            </div>
                        </>}
                    </div>
                    <div className={`tab-pane fade border-left border-bottom border-right p-4 ` + (tabName === 'subscriptions' ? 'show active' : '')} role="tabpanel">
                        <div className="container">
                            <div className="row">
                                <div className="col-6">
                                    <h2>Featured Listing Subscriptions</h2>
                                    <div className="card">
                                        <div className="card-body">
                                        <div className="row">
                                                { !promotionalListingFeatured.isValid && <div className="col-12"><span className="text-danger">{promotionalListingFeatured.error}</span><br/></div>}
                                                <div className="col-2">
                                                    <label className="text-bold">Promotional Listing : </label>
                                                </div>
                                                <div className="col-4">
                                                    <DatePicker
                                                    className="form-control"
                                                    placeholderText="Start Date" 
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={promotionalListingFeatured.startDate}
                                                    onChange={(date) => this.onChangePromotionalListingFeatured(date, 'startDate')}
                                                    name="promotionalFeaturedStartDate"
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <DatePicker
                                                    className="form-control"
                                                    placeholderText="End Date" 
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={promotionalListingFeatured.endDate}
                                                    onChange={(date) => this.onChangePromotionalListingFeatured(date, 'endDate')}
                                                    name="promotionalFeaturedStartDate"
                                                    />
                                                </div>
                                                <div className="col-2">
                                                    <div className="input-group-append">
                                                        { promotionalListingFeatured.promoListing != true && <button className="btn btn-secondary" onClick={() => this.saveFeaturedPromotional()} type="button">Save</button>}
                                                        { promotionalListingFeatured.promoListing == true && <button className="btn btn-danger" onClick={() => this.cancelFeaturedPromotional()} type="button">Cancel</button>}                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <br/>
                                            <table className="table table-sm table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Company</th>
                                                        <th className="text-right">Tools</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {featuredSubscriptions.map((subscription, index) => {
                                                    return (<tr key={index}>
                                                        <td>{subscription.receiver_name}</td>
                                                        <td className="text-right">
                                                            <button className="btn btn-sm btn-secondary" onClick={() => this.viewSubscription('featured', subscription.subscriptionId)}>View</button>
                                                        </td>
                                                    </tr>);
                                                })}
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <h2>Sponsor Subscriptions</h2>
                                    <div className="card">
                                        <div className="card-body">

                                            <table className="table table-sm table-striped">
                                                <thead>
                                                <tr>
                                                    <th>Company</th>
                                                    <th>Status</th>
                                                    <th className="text-right">Tools</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {sponsorSubscriptions.map((subscription, index) => {
                                                    return (<tr key={index}>
                                                        <td><a href={'https://www.seniorplicity.com/community/' + subscription.listingId + "/view"} target="_blank">{subscription.receiver_name}</a></td>
                                                        <td>{sponsorshipStatus[parseInt(subscription.subscriptionStatus)]}</td>
                                                        <td className="text-right">
                                                            <button className="btn btn-sm btn-secondary" onClick={() => this.viewSubscription('sponsor', subscription.subscriptionId)}>View</button>
                                                        </td>
                                                    </tr>);
                                                })}
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <h2>Job Board Listings</h2>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                { !promotionalListingJobBoard.isValid && <div className="col-12"><span className="text-danger">{promotionalListingJobBoard.error}</span></div>}
                                                <div className="col-2">
                                                    <label className="text-bold">Promotional Listing : </label>
                                                </div>
                                                <div className="col-3 px-2">
                                                    <select 
                                                    name="promotionalJobBoardAmount" 
                                                    value={promotionalListingJobBoard.amount} 
                                                    onChange={(event) => this.onChangePromotionalListingJobBoard(event, 'amount')}
                                                    className="form-control">
                                                        <option>Amount</option>
                                                        <option value="1">1-Job</option>
                                                        <option value="2">2-Job</option>
                                                        <option value="3">3-Job</option>
                                                        <option value="4">4-Job</option>
                                                        <option value="5">5-Job</option>
                                                        <option value="6">6-Job</option>
                                                    </select>
                                                </div>
                                                <div 
                                                name="promotionalJobBoardDuration" 
                                                value={promotionalListingJobBoard.duration} 
                                                onChange={(event) => this.onChangePromotionalListingJobBoard(event, 'duration')}
                                                className="col-3 px-2">
                                                    <select className="form-control">
                                                        <option>Duration</option>
                                                        <option value="30">30-days</option>
                                                        <option value="60">60-days</option>
                                                        <option value="90">90-days</option>
                                                    </select>
                                                </div>
                                                <div className="col-3 px-2">
                                                    <input 
                                                    type="text"
                                                    name="promotionalJobBoardStartDate" 
                                                    value={promotionalListingJobBoard.startDate} 
                                                    onChange={(event) => this.onChangePromotionalListingJobBoard(event, 'startDate')} 
                                                    placeholder="End Date" 
                                                    className="form-control"
                                                    />
                                                </div>
                                                <div className="col-1">
                                                    <div className="input-group-append">
                                                        <button className="btn btn-secondary" onClick={() => this.saveJobBoardPromotional()} type="button">Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`tab-pane fade border-left border-bottom border-right p-4 ` + (tabName === 'stats' ? 'show active' : '')} role="tabpanel">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h2>Listing Analytics</h2>
                                    <div className="card">
                                        <div className="card-body">


                                            <button className="btn btn-success" onClick={() => this.refreshStats(dataResults.community_provider_id)}>Refresh</button>

                                            <p><b>Total times listing appeared in search results: </b>
                                                {this.state.analytics.listingSearchResults}</p>

                                            <p><b>Total times listing was viewed: </b>
                                                {this.state.analytics.communityPageView}</p>

                                            <p><b>Total times listing was shared: </b>
                                                {this.state.analytics.shareListing}</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    { (limitAccess.includes("Delete-Business") || authRole == 1) && 
                    <div className={`tab-pane fade border-left border-bottom border-right p-4 ` + (tabName === 'delete' ? 'show active' : '')} role="tabpanel">
                        <p><b>This will delete the listing along with all associated related information.</b></p>
                        <button className="btn btn-sm btn-danger" onClick={() => this.deleteListingConfirm()}>Delete Listing</button><br/>
                    </div>
                    }
                </div>

                <ModalWindow
                    modalData={modalData}
                    closeModalWindow={this.closeModalWindow}
                    modalFunctionController={this.modalFunctionController}
                />
            </CardBody>
            <CardFooter>

            </CardFooter>
        </Card>);
    }
}
class DataFields extends Component {
    render() {
        const { field, fieldName, fieldType, fieldLabel, handleCheckboxChange, fieldPlaceHolder, value, onChange, changeValue, error, tableName, id } = this.props;
        return (
            <FormInput
                id={id}
                field={field}
                type={fieldType}
                name={fieldName}
                defaultValue={field.defaultValue}
                dropdown={field.dropdownData}
                valueOptions={field.valueOptions}
                checkboxData={field.checkboxData}
                colSize={field.colSize}
                value={value}
                changeValue={changeValue}
                tableName={tableName}
                onChange={onChange}
                error={error}
                label={fieldLabel}
                handleCheckboxChange={handleCheckboxChange}
                placeholder={fieldPlaceHolder}
            />
        );
    }
}
class DataButtons extends Component {
    render () {
        return (<div className="container my-3">
            <div className="row">
                <div className="col-6">
                    <Button onClick={() => this.props.onSave()} color="primary">
                        <SaveIcon size={18} />{' '}
                        Save
                    </Button>
                </div>
            </div>

        </div>);
    }
}
const mapStateToProps = state => ({
    auth: state.auth.user,
    data: state.pages.data,
});

export default connect(mapStateToProps, {saveProviderTypes, saveCommunityTypes, getUserRoles, getCustomRecord, customSaveRecord, deleteListing, loadSubscriptionsById, refreshStats, sendInviteUserToTeam, savePromotionalListing, cancelPromotionalListing, savePromotionalJobBoard, cancelPromotionalJobBoard})(DataEdit);