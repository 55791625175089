import React, {Component, Fragment} from "react";
import {Link, Redirect} from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input, UncontrolledAlert
} from "reactstrap";
import {connect} from "react-redux";
import {forgotPass, checkVerificationCode, saveForgotPass} from "../../client/actions/auth";

class ResetPassword extends Component {
  constructor(props)
  {
    super(props);
    this.state = {
      sentPassword: false,
      username: '',
      verificationCode: '',
      password: '',
      passwordConfirm: '',
      processComplete: false,
      showCodeScreen: false,
      showResetScreen: false,
      showUserScreen: true
    };
  };
  componentDidMount()
  {
    this.makeFocus();
  }
  componentDidUpdate(prevProps, prevState, snapshot)
  {
    if (prevProps !== this.props)
    {

      console.log(this.props);
      if (typeof(this.props.auth) !== 'undefined' && this.props.auth !== null) {
        if (typeof(this.props.auth.status) !== 'undefined') {
          if (this.props.auth.status === true || this.props.auth.status === 'true')
          {
            if (this.props.auth.nextPage === "enterCode") {
              this.setState({successMessage: this.props.auth.message, processComplete: false, showCodeScreen: true, showUserScreen: false, showResetScreen: false});
            }
            if (this.props.auth.nextPage === "newPass") {
              this.setState({successMessage: this.props.auth.message, processComplete: false, showCodeScreen: false, showUserScreen: false, showResetScreen: true});
            }
            if (this.props.auth.nextPage === "complete") {
              this.setState({successMessage: this.props.auth.message, processComplete: true,  showCodeScreen: false, showUserScreen: false, showResetScreen: false});
            }
          }
          if (this.props.auth.status === false || this.props.auth.status === 'false')
          {
            this.setState({errorMessage: this.props.auth.message});
          }
        }
      }
    }
  }
  makeFocus = () => {
    try {
      window.username.focus();
    }
    catch {

    }
  };

  onSubmit = () => {
    const { username } = this.state;
    this.props.resetPassword( username);
  };
  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value, errorMessage: '', successMessage: ''});
  };
  onKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (this.state.username !== '' && this.state.password !== '') {
        this.onSubmit();
      }
    }
  };
  onSubmit = () => {
    this.props.forgotPass(this.state.username);
  };
  onSubmitVerification = () => {
    this.props.checkVerificationCode(this.state.verificationCode, this.state.username);
  };
  onSubmitPassword = () => {
    this.props.saveForgotPass(this.state.username, this.state.verificationCode, this.state.password, this.state.passwordConfirm);
  };
  onFormSubmit = (e) => {
    e.preventDefault();
  };
  render() {
    const { processComplete, username, showResetScreen, showUserScreen, showCodeScreen, password, passwordConfirm, verificationCode, errorMessage, successMessage } = this.state;
    if (processComplete === true) {
      return (<Redirect to="/" />);
    } else {
      return (<React.Fragment>
        <div className="text-center mt-4">
          <img
              src="/assets/images/logo.png"
              alt="Seniorplicity"
              className="img-fluid rounded-circle"
              width="132"
              height="132"
          />
          <h2>SeniorPlicity</h2>
          <small>Control Panel</small>
        </div>

        <Card>
          <CardBody>
            <div className="m-sm-4">

              {errorMessage && <UncontrolledAlert color="danger">
                <div className="alert-message">
                  {errorMessage}
                </div>
              </UncontrolledAlert>}
              {successMessage && <UncontrolledAlert color="success">
                <div className="alert-message">
                  {successMessage}
                </div>
              </UncontrolledAlert>}

              {showUserScreen === true && <Fragment>
                <div className="text-center">
                  <h1 className="h2">Reset password</h1>
                  <p className="lead">Enter your email to reset your password.</p>
                </div>

                <Form onSubmit={() => this.onFormSubmit()}>
                  <FormGroup>
                    <Label>Username / Email</Label>
                    <Input
                        bsSize="lg"
                        type="email"
                        id="username"
                        name="username"
                        value={username}
                        placeholder="Enter your username"
                        onChange={this.onChange}
                        onKeyPress={this.onKeyPress}
                    />
                  </FormGroup>
                  <div className="text-center mt-3">
                    <Button color="primary" size="lg" onClick={() => this.onSubmit()}>
                      Reset password
                    </Button>
                    <br/>
                    <br/>
                    <Link to="/auth/sign-in">
                      <Button color="secondary" size="sm">
                        Back
                      </Button>
                    </Link>
                  </div>
                </Form>
              </Fragment>}

              {showCodeScreen === true && <Fragment>
                <div className="text-center">
                  <h1 className="h2">Reset password</h1>
                  <p className="lead">Check your email and enter the code from your email below.</p>
                </div>
                <form onSubmit={this.onFormSubmit}>
                  <div className="form-group row my-5">
                    <label className="col-sm-4 col-form-label text-right">Verification Code</label>
                    <div className="col-sm-8">
                      <input
                          type="text"
                          name="verificationCode"
                          onChange={this.onChange}
                          className="form-control"
                          autoComplete="section-red verificationCode"
                          value={verificationCode}
                      />
                    </div>
                  </div>
                  <div className="text-center mt-3">
                    <Button color="primary" size="lg" onClick={() => this.onSubmitVerification()}> SUBMIT</Button>
                    <br/><br/>
                    <Link to="/auth/sign-in">
                    <Button color="secondary" size="sm">
                      Back
                    </Button>
                  </Link>
                  </div>
                </form>
              </Fragment>}

              {showResetScreen === true && <Fragment>
                <div className="text-center">
                  <h1 className="h2">Reset password</h1>
                  <p className="lead">Enter your new password</p>
                </div>
                <form onSubmit={this.onFormSubmit}>
                  <div className="form-group row my-5">
                    <label className="col-sm-4 col-form-label text-right">Password</label>
                    <div className="col-sm-8">
                      <input
                          type="password"
                          name="password"
                          onChange={this.onChange}
                          className="form-control"
                          autoComplete="section-red new-password"
                          value={password}
                      />
                    </div>
                  </div>
                  <div className="form-group row my-5">
                    <label className="col-sm-4 col-form-label text-right">Confirm Password</label>
                    <div className="col-sm-8">
                      <input
                          type="password"
                          name="passwordConfirm"
                          onChange={this.onChange}
                          className="form-control"
                          autoComplete="section-red new-password"
                          value={passwordConfirm}
                      />
                    </div>
                  </div>
                  <button className="btn btn-primary btn-block" onClick={this.onSubmitPassword}><i className="fas fa-unlock-alt" /> SUBMIT</button>
                </form>
              </Fragment>}

            </div>
          </CardBody>
        </Card>
      </React.Fragment>);
    }
  }
}

const mapStateToProps = state => ({
  auth: state.auth.user
});

export default connect(mapStateToProps, {forgotPass, checkVerificationCode, saveForgotPass} )(ResetPassword);
