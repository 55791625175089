import React, {Component} from "react";
import {connect} from "react-redux";
import {loadDashboard} from "../client/actions/apiActions";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageMode: "loading",
        }
    }


    render() {
        const {  } = this.state;
        return (<div className="container">
            <div className="row">
                <div className="col-12">
                    coming soon
                </div>
            </div>
        </div>);
    }
}

const mapStateToProps = state => ({
    auth: state.auth.user,
    data: state.pages.data
});

export default connect(mapStateToProps, {loadDashboard} )(Dashboard);