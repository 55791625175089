import {
    GET_DATA
} from './types';
import axios from 'axios';

import { ApiURL } from "../../config";
import {tokenConfig} from "./apiActions";

export const getCustomRecord = (dataType, recordId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/clients/getCustomRecord`, {dataType, recordId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const customSaveRecord = (dataType, recordId, data, fields) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/clients/customSaveRecord`, {dataType, recordId, data, fields}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const deleteListing = (listingId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/clients/deleteListing`, {listingId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const sendInviteUserToTeam = (teamId, listingId, userRole, emailAddress) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/clients/sendInviteUserToTeam`, {teamId, listingId, userRole, emailAddress}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const saveProviderTypes = (listingId, providerType, providerSubType) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/clients/saveProviderTypes`, {listingId, providerType, providerSubType}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const saveCommunityTypes = (listingId, types) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/clients/saveCommunityTypes`, {listingId, types}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const refreshStats = (listingId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/clients/refreshStats`, {listingId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
