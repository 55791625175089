import React, { Component, Fragment} from 'react';
import classnames from 'classnames';
import ShowInputError from "./ShowInputError";
import Loader from "../layout/Loader";
import {ApiURL} from "../../config";
import store from "../../client/store";

class FormInputImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploading: false,
            uploadErrorMessage: ''
        };
        this.onUpload = this.onUpload.bind(this);
        this.onDeleteFile = this.onDeleteFile.bind(this);
    };
    componentDidMount() {
        this.setState({
            fieldData: this.props.field
        })
    }

    onUpload = (e) => {
        const files = Array.from(e.target.files);
        this.setState({ uploading: true });
        const formData = new FormData();
        const token = store.getState().auth.token;
        let imageFieldName = e.target.name;
        formData.append('name', imageFieldName);
        formData.append('id', this.props.id);
        formData.append('tableName', this.props.tableName);

        files.forEach((file, i) => {
            formData.append(i, file);
        });

        fetch(`${ApiURL}/image-upload`, {
            method: 'POST',
            headers: {'Authorization': token},
            body: formData
        })
        .then(res => res.json())
        .then(images => {
            this.setState({image: images.newFileName, uploading: false});
            this.props.onChange("image", images.newFileName, this.props.field)
        })
    };
    onDeleteFile = () => {
        this.props.onChange("image", '', this.props.field)
    };
    render() {
        const {colSize, name, value, label, placeholder, helpText, error} = this.props;
        const { uploading } = this.state;

        let divColSize;
        if (colSize === 0) divColSize = 0;
        if (colSize === 1) divColSize = 12;
        if (colSize === 2) divColSize = 6;
        if (colSize === 3) divColSize = 4;
        if (colSize === 4) divColSize = 8;

        return (<div className={`col-12 align-bottom col-sm-${divColSize}`}>
            <div className="container p-0">
                <div className="form-group row border-bottom pb-2">
                    {value && <Fragment>
                        <label className="col-12" htmlFor={name}>{label}</label>
                        <div className="col-12 text-center">
                            <img src={value} border="2" width="100" className="img-fluid mb-2" alt="" /><br/>

                            <a href={value} target="_blank" rel="noopener noreferrer" className="btn btn-success">View Image</a> {' '}
                            <button className="btn btn-danger" onClick={this.onDeleteFile}>Delete Image</button>
                        </div>
                        <div className="col-12">
                            <input
                                id={name}
                                name={name}
                                type="file"
                                className={classnames({ 'is-invalid': error})}
                                onChange={this.onUpload}
                                aria-describedby={name+"Help"}
                                placeholder={placeholder}
                            />
                        </div>
                    </Fragment>}
                    {(value === '' || typeof(value) === 'undefined') && <Fragment>
                        <label className="col-4" htmlFor={name}>{label}</label>
                        <div className="col-8">
                            <input
                                id={name}
                                name={name}
                                type="file"
                                className={classnames({ 'is-invalid': error})}
                                onChange={this.onUpload}
                                aria-describedby={name+"Help"}
                                placeholder={placeholder}
                            />
                        </div>
                    </Fragment>}
                    {uploading && <Loader />}
                    {helpText && <small id={name + "Help"} className="col-12 form-text text-muted">{helpText}</small>}
                    {error && <ShowInputError error={error} />}
                </div>
            </div>
        </div>);
    }
}

export default FormInputImage
