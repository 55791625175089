import React, {Component} from "react";
import {connect} from "react-redux";
import {getListingAmount,saveListingAmount} from "../client/actions/apiActions";
import {toastr} from "react-redux-toastr";

class Subscriptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            featuredListingAmount: '',
			premiumListingAmount: '',
			isValidForm: false,
			errorMessages: {
				featuredListingAmount: '',
				featuredListingAmount: ''
			},
            timeOut: 5000,
            showCloseButton: true,
            progressBar: true,
            position: "top-right"
            
        }
    }

    showToastr = (type, title, message) => {
        const options = {
            timeOut: parseInt(this.state.timeOut),
            showCloseButton: this.state.showCloseButton,
            progressBar: this.state.progressBar,
            position: this.state.position
        };
        const toastrInstance =
            type === "info"
                ? toastr.info
                : type === "warning"
                ? toastr.warning
                : type === "error"
                    ? toastr.error
                    : toastr.success;

        toastrInstance(
            title,
            message,
            options
        );
    }

    componentDidMount() {
        this.loadConfig();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.setState({
                featuredListingAmount: this.props.data.featured_listing_amount,
                premiumListingAmount: this.props.data.premium_listing_amount,
                isValidForm: true
            });
            let data = this.props.data;
            if (data.success == true) {
                this.showToastr("success", "Saved Successfully", data.message);
                this.setState({
                    featuredListingAmount: this.props.data.featured_listing_amount,
                    premiumListingAmount: this.props.data.premium_listing_amount,
                });
            }
            if (data.success == false) {
                this.showToastr("danger", "Error", data.message);

                this.setState({
                    featuredListingAmount: this.props.data.featured_listing_amount,
                    premiumListingAmount: this.props.data.premium_listing_amount,
                });
            }
        }
    }

    loadConfig = () => {
        this.props.getListingAmount();
    }

    onChange = (entry) => {
		this.setState({
			[entry.target.name]: entry.target.value
		})

		let isValidForm = false;
		const name = entry.target.name;
		const value = entry.target.value;
		const errorMessages = this.state.errorMessages;

		if (value == "") {
			errorMessages[name] = 'This field is required.';
		}else{
			isValidForm = true;
		}
		this.setState({errorMessages, isValidForm});

	}

	saveForm = () => {
		const { featuredListingAmount, premiumListingAmount } = this.state;
		const errorMessages = this.state.errorMessages;
		errorMessages.featuredListingAmount = errorMessages.premiumListingAmount = '';

		this.setState({errorMessages});
		
		if (featuredListingAmount == "") {
			errorMessages.featuredListingAmount = 'This field is required.';
		}
		if(premiumListingAmount == "") {
			errorMessages.premiumListingAmount = 'This field is required.';
		}else{
			this.props.saveListingAmount(featuredListingAmount, premiumListingAmount);
		}
		this.setState({errorMessages,isValidForm:false});

	}


    render() {
        const { featuredListingAmount, premiumListingAmount, errorMessages, isValidForm } = this.state;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Update Listing Prices</h2>
                        <div className="card">
                            <div className="card-body">
                                
                                <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()} >
    
                                    <legend>Listing Prices</legend>
                                
                                    <div className="form-group">
                                        <label htmlFor="featuredListingAmount">Featured Listing Amount</label>
                                        <input 
                                        type="number" 
                                        className="form-control"
                                        name="featuredListingAmount" 
                                        onChange={this.onChange}
                                        id="featuredListingAmount"
                                        placeholder="Featured listing amount"
                                        value={featuredListingAmount}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="premiumListingAmount">Premium Listing Amount</label>
                                        <input 
                                        type="number" 
                                        className="form-control"
                                        name="premiumListingAmount" 
                                        onChange={this.onChange}
                                        id="premiumListingAmount"
                                        placeholder="Premium listing amount"
                                        value={premiumListingAmount}
                                        />
                                    </div>
                                
                                    <button className="btn btn-primary" disabled={!isValidForm} onClick={this.saveForm}>Update</button>
                                </form>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth.user,
    userPermissions: state.auth.user.userRole,
    data: state.pages.data,
});

export default connect(mapStateToProps, { getListingAmount,saveListingAmount })(Subscriptions);
