import React, {Fragment} from 'react';
import classnames from 'classnames';
import ShowInputError from "./ShowInputError";
import DatePicker from 'react-date-picker'
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

const FormInputDate = ({field, name, value, label, colSize, placeholder, type, helpText, onChange, error, required}) => {
    let divColSize;
    if (colSize === 0) divColSize = 0;
    if (colSize === 1) divColSize = 12;
    if (colSize === 2) divColSize = 6;
    if (colSize === 3) divColSize = 4;
    if (colSize === 4) divColSize = 8;

    const onChangeDate = (date) => {
        if (date) {
            let moment = require('moment'); // require
            let newDate = moment(date).format('YYYY-MM-DD');
            onChange("custom", newDate, field);
        } else {
            onChange("custom", '', field);
        }
    }
    let newValue;
    if (value === null || value === '') {
        newValue = '';
    } else {
        newValue = new Date(value + "T00:00:00");
    }

    return (<div className={`col-12 align-bottom col-sm-${divColSize}`}>
        <div className="container p-0">
            <div className="form-group row border-bottom pb-2">
                <label htmlFor={name} className={classnames('col-sm-12 m-0 pt-2 pb-2', { 'is-invalid': error})}>{label}</label>
                <div className="col-sm-12">
                    <DatePicker
                        value={newValue}
                        onChange={onChangeDate}
                        className={classnames('form-control form-control-sm', { 'is-invalid': error})}
                    />
                </div>
                {helpText && <Fragment><div className="col-4">&nbsp;</div><small id={name + "Help"} className="col-8 form-text text-muted">{helpText}</small></Fragment>}
                {error && <ShowInputError error={error} />}
            </div>
        </div>
    </div>)
};

export default FormInputDate
