import React, {Component} from "react";
import {
  Col,
  Container,
  Row, UncontrolledAlert
} from "reactstrap";
import {connect} from "react-redux";
import { getNotificationList, markNotificationRead } from "../client/actions/apiActions";
import {AlertCircle, Bell, Home, MessageSquare as MessageIcon, UserPlus} from "react-feather";
import {languageText} from "../config";
import ProfileLinks from "./ProfileLinks";

const tableName = "users";

class ProfileNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: []
    }
  }
  componentDidMount() {
    this.props.getNotificationList();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      if (this.props.data.dataType === "notificationList") {
        this.setState({
          notifications: this.props.data.notifications
        });
      }
    }
  }
  markNotificationRead = (notificationId) => {
    this.props.markNotificationRead(notificationId);
  };

  render() {
    const { notifications } = this.state;
    const { languageCode } = this.props.auth.user;
    return (<Container fluid className="p-0">
      <Row>
        <Col md="3" xl="2">
          <ProfileLinks linkActive="/profile-notifications" />
        </Col>
        <Col md="9" xl="10">

          <h3 className="py-2 text-center">{languageText[languageCode].components[tableName + "Notifications"].pageTitle}</h3>
          <h6 className="text-muted text-center">
            {languageText[languageCode].components[tableName + "Notifications"].editSubTitle}
          </h6>
          <br />
          {notifications.length === 0 && <div className="container"><div className="row"><div className="col-12">
            <UncontrolledAlert color="warning">
              <div className="alert-message">
                There are no new notifications
              </div>
            </UncontrolledAlert>
          </div></div></div> }
          {notifications.map((notification, key) => {
            let color = "primary";
            if (notification.notificationType === "important") {
              color = "danger";
            }
            if (notification.notificationType === "message") {
              color = "dark";
            }
            if (notification.notificationType === "login") {
              color = "primary";
            }
            if (notification.notificationType === "request") {
              color = "success";
            }
            return (
                <UncontrolledAlert
                    color={color}
                    className="alert-outline-coloured"
                    key={key}
                    toggle={() => { this.markNotificationRead(notification.id)}}
                >
                  <div className="alert-icon">
                    {(notification.notificationType === "important") &&
                    <AlertCircle size={18} fixedWidth />
                    }
                    {(notification.notificationType === "message") &&
                    <MessageIcon size={18} fixedWidth />
                    }
                    {(notification.notificationType === "login") &&
                    <Home size={18} fixedWidth />
                    }
                    {(notification.notificationType === "request") &&
                    <UserPlus size={18} fixedWidth />
                    }
                    {(notification.notificationType === "notification") &&
                    <Bell size={18} fixedWidth />
                    }
                  </div>
                  <div className="alert-message">
                    {notification.time} - {notification.notificationSubject}<br />
                    {notification.notificationMessage}
                  </div>
                </UncontrolledAlert>
            )
          })}

        </Col>
      </Row>
    </Container>);
  }
}

const mapStateToProps = state => ({
  auth: state.auth.user,
  data: state.pages.data,
  dataList: state.pages.dataList,
});

export default connect(mapStateToProps, { getNotificationList, markNotificationRead } )(ProfileNotifications);