import React, {Component} from 'react';
class FormInputCheckboxElement extends Component {
    state = {
        isChecked: false,
    };

    toggleCheckboxChange = (e) => {
        const {handleCheckboxChange, value, name} = this.props;
        console.log("this.props",this.props);
        this.setState(({isChecked}) => (
            {
                isChecked: !isChecked,
            }
        ));
        handleCheckboxChange(name, value, e.target.checked);
    };

    render() {
        const { id, checked, name, value, className} = this.props;
        return (
            <React.Fragment>
                { name == 'cities' ?  
                <input
                    type="checkbox"
                    id={id}
                    name={name}
                    value={value}
                    className={className}
                    checked={checked}
                    onChange={e => this.toggleCheckboxChange(e)}
                />
                :
                <input
                    type="checkbox"
                    id={id}
                    name={name}
                    value={value}
                    className={className}
                    defaultChecked={checked}
                    onChange={e => this.toggleCheckboxChange(e)}
                />
                }
            </React.Fragment>
        );
    }
}

export default FormInputCheckboxElement