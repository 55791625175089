import React, {Component} from "react";
import {timezones, languageText} from "../config";
import DataOverview from "../components/DataOverview";
import Loader from "../components/layout/Loader";

const tableName = "users";

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organizationId: '',
            pageMode: "loading",

            tableColumns : [
                {
                    dataField: "active",
                    text: "Active",
                    type: "bool",
                    sort: false,
                    display: true,
                },
                {
                    dataField: "image",
                    text: "Image",
                    type: "image",
                    sort: true,
                    display: true,
                },
                {
                    dataField: "firstName",
                    text: "First Name",
                    sort: true,
                    display: true,
                },
                {
                    dataField: "lastName",
                    text: "Last Name",
                    sort: true,
                    display: true,
                },
                {
                    dataField: "email",
                    text: "Email",
                    sort: true,
                    display: true,
                },
                {
                    dataField: "role",
                    text: "Role",
                    sort: true,
                    display: true,
                },
                {
                    dataField: "tools",
                    sort: false,
                    display: true,
                    filter: false,
                }
            ],
            fieldData: [
                {
                    fieldName: "titleUsersName",
                    fieldType: "title",
                    colSize: 1
                },
                {
                    fieldName: "firstName",
                    fieldType: "text",
                    colSize: 3,
                    required: true,
                },
                {
                    fieldName: "lastName",
                    fieldType: "text",
                    colSize: 3,
                    required: true,
                },
                {
                    fieldName: "active",
                    fieldType: "toggle",
                    colSize: 3
                },
                {
                    fieldName: "titleLogInfo",
                    fieldType: "title",
                    colSize: 1
                },
                {
                    fieldName: "email",
                    fieldType: "email",
                    colSize: 1
                },
                {
                    fieldName: "password",
                    fieldType: "password",
                    colSize: 2
                },
                {
                    fieldName: "passwordConfirm",
                    fieldType: "password",
                    colSize: 2
                },
                {
                    fieldName: "titleContactInfo",
                    fieldType: "title",
                    colSize: 1
                },
                {
                    fieldName: "cellPhone",
                    fieldType: "phone",
                    colSize: 2
                },
                {
                    fieldName: "workPhone",
                    fieldType: "phone",
                    colSize: 2
                },
                {
                    fieldName: "website",
                    fieldType: "text",
                    colSize: 2
                },
                {
                    fieldName: "timezone",
                    fieldType: "dropdown",
                    dropdownData: timezones,
                    colSize: 2
                },
                {
                    fieldName: "image",
                    fieldType: "image",
                    colSize: 1
                },
                {
                    fieldName: "notes",
                    fieldType: "textarea",
                    colSize: 1
                },
                {
                    fieldName: "titleRoleInfo",
                    fieldType: "title",
                    colSize: 1
                },
                {
                    fieldName: "userRole",
                    fieldType: "radio",
                    colSize: 1,
                    required: true
                },
                {
                    fieldName: "limitAccess",
                    fieldType: "checkbox",
                    colSize: 1,
                    valueOptions: [
                        {
                            name: 'Delete Businesses',
                            value: 'Delete-Business',
                            id: 'Delete-Business',
                        },
                        {
                            name: 'Edit Businesses',
                            value: 'Edit-Businesses',
                            id: 'Edit-Businesses',
                        },
                        {
                            name: 'Edit Users',
                            value: 'Edit-Users',
                            id: 'Edit-Users',
                        },
                        {
                            name: 'Delete Users',
                            value: 'Delete-Users',
                            id: 'Delete-Users',
                        },
                        {
                            name: 'Add Team Members',
                            value: 'Add-Team-Members',
                            id: 'Add-Team-Members',
                        },
                        {
                            name: 'Remove Team Members',
                            value: 'Remove-Team-Members',
                            id: 'Remove-Team-Members',
                        }
                    ]
                },
                {
                    fieldName: "titleTerritoryInfo",
                    fieldType: "title",
                    colSize: 1
                },
                {
                    fieldName: "territory",
                    fieldType: "dropdown",
                    colSize: 2,
                    required: true
                },
                {
                    fieldName: "cities",
                    fieldType: "checkbox",
                    colSize: 1,
                    required: true
                },
                {
                    fieldName: "titleAddressInfo",
                    fieldType: "title",
                    colSize: 1
                },
                {
                    fieldName: "address",
                    fieldType: "geoLocate",
                    colSize: 1
                },
                {
                    fieldName: "addressLatitude",
                    fieldType: "hidden",
                    colSize: 0
                },
                {
                    fieldName: "addressLongitude",
                    fieldType: "hidden",
                    colSize: 0
                },
                {
                    fieldName: "organizationId",
                    fieldType: "hidden",
                    colSize: 0
                },
            ],
            fieldDataDefault: {
            },
        }
    }
    componentDidMount() {
        let organizationId = '';
        if (typeof(this.props.match.params.organizationId) !== 'undefined') {
            organizationId = this.props.match.params.organizationId;
        }
        if (organizationId === '') {
            organizationId = this.props.auth.user.user.organizationId;
        }
        this.setState({organizationId: organizationId, pageMode: 'data'});

    }

    render() {
        const { pageMode, tableColumns, fieldData, fieldDataDefault, organizationId } = this.state;
        if (pageMode === "data") {
        return (<DataOverview
            tableName={tableName}
            organizationId={organizationId}
            tableColumns={tableColumns}
            fieldData={fieldData}
            fieldDataDefault={fieldDataDefault}
        />);}else{return(<Loader />);}
    }
}
export default Users;
