import React, {Component} from "react";
import DataOverview from "../components/DataOverview";
import {languageText} from "../config"; // Import css

const tableName = "meta_data";
class MetaData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageMode: "loading",
            searchColumns : [
                "id",
                "value",
                "key",
                "min_price",
                "max_price"
            ],
            tableColumns : [
                {
                    dataField: "value",
                    text: "Value",
                    sort: true
                },
                {
                    dataField: "key",
                    text: "Key",
                    sort: true
                },
                {
                    dataField: "min_price",
                    text: "Min Price",
                    sort: true
                },
                {
                    dataField: "max_price",
                    text: "Max Price",
                    sort: true
                },
                {
                    dataField: "parent_id",
                    text: "Parent",
                    sort: true
                },
                {
                    dataField: "oldId",
                    text: "Parent",
                    sort: true
                },
                {
                    dataField: "tools",
                    text: "Tools",
                    sort: false,
                }
            ],
            fieldData: [
                {
                    fieldName: "value",
                    fieldType: "text",
                    colSize: 3,
                    required: true,
                },
                {
                    fieldName: "key",
                    fieldType: "text",
                    colSize: 3,
                    required: true,
                },
                {
                    fieldName: "min_price",
                    fieldType: "text",
                    colSize: 3,
                    required: false,
                },
                {
                    fieldName: "max_price",
                    fieldType: "text",
                    colSize: 3,
                    required: false,
                },
                {
                    fieldName: "parent_id",
                    fieldType: "text",
                    colSize: 3,
                    required: false,
                },
                {
                    fieldName: "oldId",
                    fieldType: "text",
                    colSize: 3,
                    required: false,
                },
            ],
            fieldDataDefault: {
                name: '',
                active: 1,
            },
        }
    }
    render() {
        const { searchColumns, tableColumns, fieldData, fieldDataDefault } = this.state;
        const { languageCode } = this.props.auth.user.user;
        return (<DataOverview
            tableName={tableName}
            searchColumns={searchColumns}
            tableColumns={tableColumns}
            fieldData={fieldData}
            fieldDataDefault={fieldDataDefault}
        />);
    }
}
export default MetaData;
