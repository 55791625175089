import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { Badge, Collapse } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import routes from "../../client/routes";
import {languageText} from "../../config";

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon: Icon,
    isOpen,
    children,
    onClick,
    location,
    to
  }) => {
    const getSidebarItemClass = path => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/" && path === "/dashboard")
        ? "active"
        : "";
    };
    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <span
          data-toggle="collapse"
          className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          <Icon size={18} className="align-middle mr-3" />
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={"sidebar-dropdown list-unstyled"}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon: Icon, location, to }) => {
    const getSidebarItemClass = path => {
      return location.pathname === path ? "active" : "";
    };
    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <NavLink to={to}  className="sidebar-link" activeClassName="active">
          {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
          {name}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggle = index => {
    // Collapse all elements
    Object.keys(this.state).forEach(
      item =>
        this.state[index] ||
        this.setState(() => ({
          [item]: false
        }))
    );

    // Toggle selected element
    this.setState(state => ({
      [index]: !state[index]
    }));
  };

  UNSAFE_componentWillMount() {
    /* Open collapse element that matches current url */
    const pathName = this.props.location.pathname;

    routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/";

      this.setState(() => ({
        [index]: isActive || isOpen || isHome
      }));
    });
  }

  render() {
    const { sidebar, layout } = this.props;
    let userInfo = {};
    if (typeof(this.props.auth) !== 'undefined' && this.props.auth !== null) {
      if (typeof(this.props.auth.user) !== 'undefined' && this.props.auth.user !== null) {
          userInfo = this.props.auth.user.user;
      }
    }
    const { languageCode } = this.props.auth.user.user;
    const { userPermissions } = this.props;

    return (
      <nav
        className={
          "sidebar" +
          (!sidebar.isOpen ? " toggled" : "") +
          (sidebar.isSticky ? " sidebar-sticky" : "")
        }
      >
        <div className="sidebar-content">
          <PerfectScrollbar>
              <div className="w-100 text-center">
                  <a className="sidebar-brand" href="/">
                      <img
                          src="/assets/images/logo.png"
                          alt="Seniorplicity"
                          className="img-fluid rounded-circle"
                          width="160"
                      />
                  </a>
                  <hr />
              </div>


            <ul className="sidebar-nav">
              {routes.map((category, index) => {
                return (
                  <React.Fragment key={index}>
                    {category.header ? (<li className="sidebar-header">{category.header}</li>) : null}

                    {category.children && (<SidebarCategory
                        name={category.name}
                        badgeColor={category.badgeColor}
                        badgeText={category.badgeText}
                        icon={category.icon}
                        to={category.path}
                        isOpen={this.state[index]}
                        onClick={() => this.toggle(index)}
                      >
                        {category.children.map((route, index) => {
                            return (<SidebarItem
                                key={index}
                                name={route.name}
                                to={route.path}
                                badgeColor={route.badgeColor}
                                badgeText={route.badgeText}
                            />);
                        })}
                      </SidebarCategory>)}

                      {(typeof(category.children) === 'undefined' || category.children === null) && (<SideBarLink
                          userPermissions={userPermissions}
                          name={category.name}
                          to={category.path}
                          icon={category.icon}
                          badgeColor={category.badgeColor}
                          badgeText={category.badgeText}
                      />)}
                  </React.Fragment>
                );
              })}
            </ul>

            {!layout.isBoxed && !sidebar.isSticky ? (
              <div className="sidebar-bottom d-none d-lg-block">
                <div className="media">
                    {userInfo.image && <img
                        src={userInfo.image}
                        className="rounded-circle mr-3"
                        alt={`${userInfo.firstName} ${userInfo.lastName}`}
                        width="40"
                        height="40"
                    />}
                  <div className="media-body">
                    <h5 className="mb-1">{userInfo.firstName} {userInfo.lastName}</h5>
                    <div>
                      <FontAwesomeIcon
                        icon={faCircle}
                        className="text-success"
                      />{" "}
                        {languageText[languageCode].sideBar.online}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </PerfectScrollbar>
        </div>
      </nav>
    );
  }
}

class SideBarLink extends React.Component {
    render() {
        const { name, to, icon, badgeColor, badgeText } = this.props;
        return (<SidebarItem
            name={name}
            to={to}
            icon={icon}
            badgeColor={badgeColor}
            badgeText={badgeText}
        />);
    }
}

export default withRouter(
    connect(store => ({
        sidebar: store.sidebar,
        layout: store.layout,
        auth: store.auth,
        userPermissions: store.auth.user.userRole
    }))(Sidebar)
);
