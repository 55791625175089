import React, {Fragment, Component, useEffect, useState, useCallback} from "react";
import { Button, Card, CardHeader, CardTitle, Table} from "reactstrap";
import {
    ChevronDown as SortDown, Download as DownloadIcon, Search as SearchIcon, Users as UsersIcons,
    XOctagon as ResetIcon,ChevronUp as SortUp, Edit as EditIcon, Trash2 as DeleteIcon,
    PlusSquare as AddIcon, CheckCircle as ActiveIcon, XCircle as InActiveIcon, EyeOff as ArchiveIcon,
    Eye as UnArchiveIcon, Mail as EmailIcon, MessageSquare as ChatIcon, Eye as ViewIcon, Filter as FilterIcon,
    Plus as PlusIcon, X as TimesIcon
} from "react-feather";
import {languageText} from "../config";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import { archiveSubDataRecord } from "../client/actions/apiActions";

class DataList extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            if (this.props.data.dataType === "archiveRecord")
            {
                console.log("DataList componentDidUpdate archiveRecord");
            }
        }
    }

    onArchive = (id) => {
        console.log("DataList archiveRecord")
        this.props.archiveSubDataRecord(this.props.tableName, id);
    }

    render() {
        const {
            tableData, settings, searchText, columns, sortFieldName, searchKeyPress, resetSearch,
            sortDirection, onAdd, onViewsUsers, changeValue, searchOverview, onExport, recordsPerPage,
            onChangeRecordsPerPage, changeSort, onEdit, onDelete, onUpdatePage, currentPage, totalPages, totalRecords,
            organizationId, userPermissions, overviewSettings, showFilters, filters, updateFilter, toggleFilters, showToastr
        } = this.props;
        let limitAccess = [];
        let authRole;
        let incrUid;
        let createdByID;
        let {  tableName, languageTable } = this.props;
        let pageTitle;
        let listTitle;
        let listSubTitle;
        let addTitle;
        let addSubTitle;
        let editTitle;
        let editSubTitle;
        let languageCode;
        let languageOutput;
        
        if (this.props.auth) {
            if (typeof(languageTable) === 'undefined' || languageTable === '') {
                languageTable = tableName;
            }
            if (this.props.auth.user.languageCode) {
                languageCode = this.props.auth.user.languageCode;
            } else if (this.props.auth.user.user.languageCode) {
                languageCode = this.props.auth.user.user.languageCode;
            }
            authRole = this.props.auth.user.userRole;
            incrUid = this.props.auth.user.incr_uid;
            createdByID = this.props.auth.user.created_by;
            if (this.props.auth.user.limitAccess && this.props.auth.user.userRole == 2) {
                let authLimitAccess = this.props.auth.user.limitAccess.split(",");
                
                
                authLimitAccess.forEach((options, index) => {
                    if (options !== '' && typeof(options) !== 'undefined' && options !== undefined) {
                        limitAccess.push(options.toString());
                    }
                });
            }
            languageOutput = languageText[languageCode].components[languageTable];
            pageTitle = languageOutput.pageTitle;
            listTitle = languageOutput.listTitle;
            listSubTitle = languageOutput.listSubTitle;
            addTitle = languageOutput.addTitle;
            addSubTitle = languageOutput.addSubTitle;
            editTitle = languageOutput.editTitle;
            editSubTitle = languageOutput.editSubTitle;
        }

        let hasStatus = false;
        if (settings.showCard === false)
        {
            return (<>
                <h6 className="card-subtitle text-muted">{listSubTitle}</h6><br/>
                <div className="row align-items-center">
                    <DataListAddButtons
                        onExport={onExport}
                        onAdd={onAdd}
                        addTitle={addTitle}
                        settings={settings}
                        userPermissions={userPermissions}
                        toggleFilters={toggleFilters}
                        showFilters={showFilters}
                        limitAccess={limitAccess}
                        authRole={authRole}
                    />
                    <SearchFunction
                        settings={settings}
                        searchText={searchText}
                        searchOverview={searchOverview}
                        resetSearch={resetSearch}
                        changeValue={changeValue}
                        searchKeyPress={searchKeyPress}
                    />
                </div>
                <SearchFilters
                    columns={columns}
                    showFilters={showFilters}
                    filters={filters}
                    updateFilter={updateFilter}
                    showToastr={showToastr}
                />
                <DataListTable
                    settings={settings}
                    columns={columns}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onUpdatePage={onUpdatePage}
                    recordsPerPage={recordsPerPage}
                    sortDirection={sortDirection}
                    sortFieldName={sortFieldName}
                    hasStatus={hasStatus}
                    languageCode={languageCode}
                    tableData={tableData}
                    tableName={tableName}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onArchive={this.onArchive}
                    changeSort={changeSort}
                    onChangeRecordsPerPage={onChangeRecordsPerPage}
                    totalRecords={totalRecords}
                    onViewsUsers={onViewsUsers}
                    userPermissions={userPermissions}
                    limitAccess={limitAccess}
                    authRole={authRole}
                    incrUid={incrUid}
                    createdByID={createdByID}
                />
            </>);
        }
        else
        {
            return (<Card>
                <CardHeader>
                    {tableName === 'users' && organizationId !== '' && typeof(organizationId) !== 'undefined' && <div className="row mb-2">
                        <div className="col-6">
                            <CardTitle tag="h5">{listTitle}</CardTitle>
                            <h6 className="card-subtitle text-muted">{listSubTitle}</h6>
                        </div>
                        <div className="col-6 text-right">
                            <Link className="btn btn-dark btn-sm" to="/organizations/manage">Back to Organizations</Link>
                        </div>
                    </div>}
                    {tableName !== 'users' && <div className="row mb-2">
                        <div className="col-12">
                            <CardTitle tag="h5">{listTitle}</CardTitle>
                            <h6 className="card-subtitle text-muted">{listSubTitle}</h6>
                        </div>
                    </div>}
                    <div className="row align-items-center">
                        <DataListAddButtons
                            onExport={onExport}
                            onAdd={onAdd}
                            addTitle={addTitle}
                            settings={settings}
                            tableName={tableName}
                            userPermissions={userPermissions}
                            toggleFilters={toggleFilters}
                            showFilters={showFilters}
                            limitAccess={limitAccess}
                            authRole={authRole}
                        />
                        <SearchFunction
                            settings={settings}
                            searchText={searchText}
                            searchOverview={searchOverview}
                            resetSearch={resetSearch}
                            changeValue={changeValue}
                            searchKeyPress={searchKeyPress}
                        />
                    </div>
                </CardHeader>
                <SearchFilters
                    columns={columns}
                    showFilters={showFilters}
                    filters={filters}
                    updateFilter={updateFilter}
                    showToastr={showToastr}
                />
                <DataListTable
                    settings={settings}
                    columns={columns}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onUpdatePage={onUpdatePage}
                    recordsPerPage={recordsPerPage}
                    sortDirection={sortDirection}
                    sortFieldName={sortFieldName}
                    hasStatus={hasStatus}
                    languageCode={languageCode}
                    tableData={tableData}
                    tableName={tableName}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onArchive={this.onArchive}
                    changeSort={changeSort}
                    onChangeRecordsPerPage={onChangeRecordsPerPage}
                    totalRecords={totalRecords}
                    onViewsUsers={onViewsUsers}
                    userPermissions={userPermissions}
                    limitAccess={limitAccess}
                    authRole={authRole}
                    incrUid={incrUid}
                    createdByID={createdByID}
                />
            </Card>);
        }
    }
}
const DataListTable = ({
   columns, currentPage, totalPages, onUpdatePage, recordsPerPage, sortDirection, sortFieldName, hasStatus,
   languageCode, tableData, tableName, onEdit, onDelete, onArchive, changeSort,
   onChangeRecordsPerPage, totalRecords, onViewsUsers, userPermissions, settings, limitAccess=[] , authRole, incrUid, createdByID
}) => {
    return (<div className="table-responsive">
        <Table striped hover size="sm">
            <thead>
                <DataListPages
                    settings={settings}
                    columns={columns}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onUpdatePage={onUpdatePage}
                    recordsPerPage={recordsPerPage}
                    onChangeRecordsPerPage={onChangeRecordsPerPage}
                />
            </thead>
            <thead className="table-dark">
                <tr>
                    {columns && <DataListColumns
                        settings={settings}
                        columns={columns}
                        sortDirection={sortDirection}
                        sortFieldName={sortFieldName}
                        changeSort={changeSort}
                        hasStatus={hasStatus}
                        languageCode={languageCode}
                        tableName={tableName}
                    />}
                </tr>
            </thead>
            <tbody>
                {tableData && <DataListData
                    settings={settings}
                    tableData={tableData}
                    columns={columns}
                    tableName={tableName}
                    onViewsUsers={onViewsUsers}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    hasStatus={hasStatus}
                    onArchive={onArchive}
                    languageCode={languageCode}
                    userPermissions={userPermissions}
                    limitAccess={limitAccess}
                    authRole={authRole}
                    incrUid={incrUid}
                    createdByID={createdByID}
                />}
            </tbody>
            <tfoot>
            <tr><td colSpan={columns.length} /></tr>
            <DataListPages
                settings={settings}
                columns={columns}
                currentPage={currentPage}
                totalPages={totalPages}
                onUpdatePage={onUpdatePage}
                recordsPerPage={recordsPerPage}
                onChangeRecordsPerPage={onChangeRecordsPerPage}
            />
            <tr>
                <td colSpan={columns.length}>
                    Total Pages : {totalPages} -
                    Total Records : {totalRecords}
                </td>
            </tr>
            </tfoot>
        </Table>
    </div>);
}

const SearchFilters = ({showFilters, filters, columns, updateFilter, showToastr}) => {
    const [filterText, setFilterText] = useState('');
    const [filterColumn, setFilterColumn] = useState('');
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const onFilterTextChange = (e) => {
        setFilterText(e.target.value);
    }
    const saveFilter = () => {
        if (filterColumn === '') {
            showToastr("error", 'You did not select a column to search', '');
        } else if (filterText === '') {
            showToastr("error", 'You did not enter a search term to search', '');
        } else {
            let display = columns.filter((column) => column.dataField === filterColumn)[0].text;
            let newFilters = filters;
            newFilters.push({
                display: display,
                keyword: filterText,
                column: filterColumn
            });
            updateFilter(newFilters);
            setFilterColumn('');
            setFilterText('');
            forceUpdate();
        }
    }

    const selectColumn = (e) => {
        setFilterColumn(e.target.value);
    }
    useEffect(() => {
        forceUpdate();
    }, [filters]);

    const deleteFilter = (columnName) => {
        let newFilters = filters.filter(filter => filter.column !== columnName);
        updateFilter(newFilters);
        forceUpdate();
    }

    if (!showFilters) return null;
    return (<div className="row">
        <div className="col-12 col-sm text-left mb-0">
            <div className="container p-0 m-0">
                <div className="row">
                    <div className="col">
                        Field:
                    </div>
                    <div className="col">
                        Search Term:
                    </div>
                    <div className="col-8">
                        &nbsp;
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <select
                            className='form-control'
                            id="recordsPerPage"
                            name="recordsPerPage"
                            onChange={selectColumn}
                            value={filterColumn}
                        >
                            <option value="">-- SELECT --</option>
                            {columns && columns.length > 0 && columns.map( (column, index) => {
                                if (column.filter === false) return null;
                                return (<option value={column.dataField} key={"option" + index}>
                                    {column.text}
                                </option>);
                            })}
                        </select>
                    </div>
                    <div className="col">
                        <div className="input-group">
                            <input
                                id="searchText"
                                name="searchText"
                                value={filterText}
                                className="form-control"
                                onChange={onFilterTextChange}
                            />
                            <div className="input-group-append">
                                <Button color="success" onClick={() => saveFilter() }>
                                    <PlusIcon size={18} />
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="col-8">
                        {filters && filters.length > 0 && filters.map((filter, index) => {
                            return (<div key={"filter" + index} className="badge badge-pill badge-primary p-2 mr-1">
                                {filter.display} : {filter.keyword} &nbsp; <ResetIcon size={18} onClick={() => deleteFilter(filter.column)} />
                            </div>);
                        })}
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

const DataListAddButtons = ({onExport, onAdd, addTitle, settings, userPermissions, tableName, showFilters, toggleFilters, limitAccess =[], authRole}) => {
    let addPermissions;
    let exportPermissions;

    if (typeof (userPermissions) != 'undefined') {
        if (tableName === "mentee") {
            addPermissions = userPermissions.menteesCanAdd === "1";
            exportPermissions = userPermissions.menteesCanExport === "1";
        } else if (tableName === "users") {
            addPermissions = userPermissions.usersCanAdd === "1" && (limitAccess.includes("Add-Team-Members") || authRole == 1); 
            exportPermissions = userPermissions.usersCanExport === "1";
        } else if (tableName === "organizations") {
            addPermissions = userPermissions.organizationsCanAdd === "1";
            exportPermissions = userPermissions.organizationsCanExport === "1";
        } else {
            addPermissions = userPermissions.tablesCanAdd === "1";
            exportPermissions = userPermissions.tablesCanExport === "1";
        }
        
    }
    if (settings && settings.hideAddButton) addPermissions = false
    return (<div className="col-12 col-sm text-left mb-2">
        {addPermissions &&
        <Button color="primary" onClick={() => onAdd() }>
            <AddIcon size={18} /> {' '}
            {addTitle}
        </Button>}
        {exportPermissions && <>
            {typeof(settings) !== 'undefined' && <>
                {settings.showExport !== false && <Button color="secondary" onClick={() => onExport() } className="ml-1">
                    <DownloadIcon size={18} /> {' '}
                    Export Data
                </Button>}
            </>}
        </>}
        {typeof(settings) !== 'undefined' && <>
            {settings.showFilters !== false && <>
                <Button color="secondary" onClick={() => toggleFilters() } className="ml-1">
                    <FilterIcon size={18} /> {' '}
                    {showFilters ? 'Hide' : 'Show'} Filters
                </Button>
            </>}
        </>}

    </div>);
}
const DataListColumns = ({columns, sortDirection, sortFieldName, changeSort, hasStatus, languageCode, tableName, settings}) => {
    return (<>
        {columns.map((column, index) => {
            let columnName = '';
            if (languageText[languageCode].components[tableName].columns[column.dataField]) {
                columnName = languageText[languageCode].components[tableName].columns[column.dataField];
            } else {
                columnName = column.name;
            }

            if (column.dataField === 'active' || column.dataField === 'status') hasStatus = true;
            if (column.type === "bool") {
                if (column.sort ===  true) {
                    return (<th key={index} className="text-center cursor-pointer">{columnName}</th>);
                } else {
                    return (<th key={index} className="text-center">{columnName}</th>);
                }
            } else {
                if (column.sort ===  true) {
                    return (<th key={index} className="cursor-pointer" onClick={() => changeSort(column.dataField, sortDirection) }>
                        {sortFieldName === column.dataField && typeof(sortDirection) !== 'undefined' && sortDirection === 'ASC' && <SortDown size={12} />}
                        {sortFieldName === column.dataField && typeof(sortDirection) !== 'undefined' && sortDirection === 'DESC' && <SortUp size={12} />}
                        {columnName}
                    </th>);
                } else {
                    if (column.dataField === 'tools') {
                        return (<th key={index} className="text-right">{columnName}</th>);
                    } else {
                        return (<th key={index}>{columnName}</th>);
                    }
                }
            }
        })}
    </>);
}
const DataListPages = ({columns, recordsPerPage, onChangeRecordsPerPage, currentPage, totalPages, onUpdatePage, settings}) => {
    const pages = [];

    // GO BACK 1 PAGE
    if (parseInt(currentPage) === 1) {
        pages.push(<li key="0" className="page-item disabled"><span className="page-link">&laquo;</span></li>);
    } else {
        pages.push(<li key="-1" className="page-item"><button className="page-link" onClick={() => onUpdatePage(1)}>First</button></li>);
        pages.push(<li key="0" className="page-item"><button className="page-link" onClick={() => onUpdatePage(currentPage-1)}>&laquo;</button></li>);
    }

    if (parseInt(totalPages) > 15)
    {
        let startPage = parseInt(currentPage) - 6;
        let endPage = parseInt(currentPage) + 6;
        if (startPage < 1) startPage=1;
        if (endPage > totalPages) endPage=totalPages;
        if (endPage < 15 && totalPages > 20) endPage = 15;
        for (let i=startPage; i <= endPage; i++) {
            if (i === parseInt(currentPage)) {
                pages.push(<li key={i} className="page-item active"><span className="page-link">{i}<span className="sr-only">(current)</span></span></li>);
            } else {
                pages.push(<li key={i} className="page-item"><button className="page-link" onClick={() => onUpdatePage(i)}>{i}</button></li>);
            }
        }
    }
    else
    {
        for (let i=1; i <= parseInt(totalPages); i++) {
            if (i === parseInt(currentPage)) {
                pages.push(<li key={i} className="page-item active"><span className="page-link">{i}<span className="sr-only">(current)</span></span></li>);
            } else {
                pages.push(<li key={i} className="page-item"><button className="page-link" onClick={() => onUpdatePage(i)}>{i}</button></li>);
            }
        }
    }

    // GO FORWARD ONE PAGE
    if (parseInt(totalPages) === parseInt(currentPage)) {
        pages.push(<li key={totalPages + 1} className="page-item disabled"><span className="page-link">&raquo;</span></li>);
    } else {
        pages.push(<li key={totalPages + 1} className="page-item"><button className="page-link" onClick={() => onUpdatePage(currentPage+1)}>&raquo;</button></li>);
        pages.push(<li key={totalPages + 2} className="page-item"><button className="page-link" onClick={() => onUpdatePage(totalPages)}>Last</button></li>);
    }

    return (<tr>
        <td colSpan={columns.length-1} className="pt-4">
            <nav aria-label="Page List">
                <ul className="pagination">
                    {pages}
                </ul>
            </nav>
        </td>
        <td className="text-right pt-4">
            <select
                className='form-control'
                id="recordsPerPage"
                name="recordsPerPage"
                value={recordsPerPage}
                //defaultValue={recordsPerPage}
                onChange={(e) => onChangeRecordsPerPage(e)}
                style={{maxWidth: '120px', display: 'inline'}}
            >
                <option value="">-- SELECT --</option>
                <option value="5">5</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="10000">all</option>
            </select>
        </td>
    </tr>);
}
const SearchFunction = ({ settings, searchText, searchOverview, resetSearch, changeValue, searchKeyPress }) => {
    if (typeof(settings) !== 'undefined') {
        if (settings.showSearch !== false) {
            return (<div className="col-12 col-sm">
                <div className="row align-items-center">
                    <div className="col-12">
                        <div className="input-group">
                            <div className="input-group-prepend"><span className="input-group-text" id="basic-addon3">Search Term:</span></div>
                            <input
                                id="searchText"
                                name="searchText"
                                value={searchText}
                                className="form-control"
                                onKeyPress={(e) => { searchKeyPress(e); } }
                                onChange={(e) => changeValue('searchText', e)}
                            />
                            <div className="input-group-append">
                                <Button color="success" onClick={() => searchOverview() }>
                                    <SearchIcon size={18} /> {' '}
                                    Search
                                </Button>
                            </div>
                            <div className="input-group-append">
                                <Button color="secondary" onClick={() => resetSearch() }>
                                    <ResetIcon size={18} /> {' '}
                                    Reset
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
        }
    }

    return (null);
}
const DataListData = ({tableData, columns, tableName, onViewsUsers, onEdit, onDelete, hasStatus, onArchive, languageCode, userPermissions, settings, limitAccess=[], authRole, incrUid, createdByID}) => {
    let userType = ["Community", "Provider", "volunteer", "Family"];
    let userRole = ["", "Admin", "Regional", "Sales"];
    let businessType = ["Community", "Provider"];
    if (tableData.length !== 0) {
        return (<>
            {tableData.map((dataRow, index) => {
                return (<tr key={index}>
                    {columns.map((column, index) => {
                        let value = dataRow[column.dataField];
                        if (column.type === "bool")
                        {
                            return (<td key={index} style={{width: 75}} className="text-center">
                                {(value === 1 || value === '1' || value === true || value === "true") && <ActiveIcon className="text-success" size={18}/>}
                                {(value === 0 || value === '0' || value === false || value === "false") && <InActiveIcon className="text-danger" size={18}/>}
                            </td>);
                        }
                        else if (column.type === "image")
                        {
                            if (value !== '' && value !== null) {
                                return (<td key={index}><img src={value} style={{maxWidth: 60}} alt="thumbnail" border="0" /></td>);
                            } else {
                                return (<td key={index}>&nbsp;</td>);
                            }
                        }
                        else if (column.type === "userType")
                        {
                            if (value !== '' && value !== null) {
                                return (<td key={index}>{userType[value]}</td>);
                            } else {
                                return (<td key={index}>&nbsp;</td>);
                            }
                        }
                        else if (column.type === "businessType")
                        {
                            if (value !== '' && value !== null) {
                                return (<td key={index}>{businessType[value]}</td>);
                            } else {
                                return (<td key={index}>&nbsp;</td>);
                            }
                        }
                        else if (column.type === "userRole")
                        {
                            if (value !== '' && value !== null) {
                                return (<td key={index}>{userRole[value]}</td>);
                            } else {
                                return (<td key={index}>&nbsp;</td>);
                            }
                        }
                        else if (column.dataField === "tools")
                        {
                            let chatPermissions = false;
                            let viewPermissions = false;
                            let editPermissions = false;
                            let deletePermissions = false;
                            let archivePermissions = false;

                            if (tableName === "mentee") {
                                chatPermissions = userPermissions.menteesCanChat === "1";
                                editPermissions = userPermissions.menteesCanEdit === "1";
                                archivePermissions = userPermissions.menteesCanArchive === "1";
                                deletePermissions = userPermissions.menteesCanDelete === "1";
                                viewPermissions = editPermissions === false;
                            } else if (tableName === "users") {
                                editPermissions = userPermissions.usersCanEdit === "1";
                                archivePermissions = userPermissions.usersCanArchive === "1";
                                let tierTwoDeletePermission = (incrUid != dataRow.id);
                                if(authRole == 1){
                                    tierTwoDeletePermission = true;
                                }
                                viewPermissions = editPermissions === false;
                                if((authRole != 1) && (dataRow.id == createdByID)){
                                    tierTwoDeletePermission = false;
                                    editPermissions = false;
                                    viewPermissions = false;
                                }
                                deletePermissions = userPermissions.usersCanDelete === "1" && (limitAccess.includes("Remove-Team-Members") || authRole == 1) && tierTwoDeletePermission;
                            } else if (tableName === "organizations") {
                                editPermissions = userPermissions.organizationsCanEdit === "1";
                                archivePermissions = userPermissions.organizationsCanArchive === "1";
                                deletePermissions = userPermissions.organizationsCanDelete === "1";
                                viewPermissions = editPermissions === false && userPermissions.organizationsCanView === "1";
                            }else if (tableName === "user") {
                                editPermissions = (limitAccess.includes("Edit-Users") || authRole == 1);
                                deletePermissions = (limitAccess.includes("Delete-Users") || authRole == 1);
                            }else if (tableName === "community_provider") {
                                editPermissions = (limitAccess.includes("Edit-Businesses") || authRole == 1);
                                deletePermissions = (limitAccess.includes("Delete-Business") || authRole == 1);
                            } else {
                                editPermissions = userPermissions.tablesCanEdit === "1";
                                deletePermissions = userPermissions.tablesCanDelete === "1";
                                archivePermissions = userPermissions.tablesCanArchive === "1";
                                viewPermissions = editPermissions === false && userPermissions.tablesCanView === "1";
                            }
                            let deleteBusiness = false;
                            let editBusinesses = false;
                            let editUsers = false;
                            let deleteUsers = false;
                            let addTeamMembers = false;
                            let removeTeamMembers = false;

                            if (settings && settings.hideDeleteButton) deletePermissions = false;

                            return (<td key={index} nowrap="true" style={{width: 220}} className="text-right">
                                {viewPermissions === true && <><Button size="sm" color="primary" onClick={() => onArchive(dataRow.id)}><ViewIcon size={18} /></Button>{' '}</>}
                                {chatPermissions === true && <>{tableName === "mentee" && dataRow.active === '1' && <><a href={`/mentee/chat/${dataRow.id}`} className="btn btn-sm btn-success"><ChatIcon size={18} /></a>{' '}</>}</>}
                                {tableName === "organizations" && <><Button size="sm" color="success" onClick={() => onViewsUsers(dataRow.id)}><UsersIcons size={18} /></Button>{' '}</>}
                                {tableName === "users" && dataRow.status === '2' && <><Button size="sm" color="success" onClick={() => onViewsUsers(dataRow.id)}><EmailIcon size={18} /></Button>{' '}</>}

                                {editPermissions === true && <>{(dataRow.editButton === "true" || dataRow.editButton === true) && <Button size="sm" color="primary" onClick={() => onEdit(dataRow.id)}><EditIcon size={18} /></Button>}{' '}</>}
                                {deletePermissions === true && <>{(dataRow.deleteButton === "true" || dataRow.deleteButton === true) && <Button size="sm" color="danger" onClick={() => onDelete(dataRow.id)}><DeleteIcon size={18} /></Button>}{' '}</>}
                                {archivePermissions === true && <>{typeof(dataRow.active) !== 'undefined' && dataRow.active === '0' && <><Button size="sm" color="warning" onClick={() => onArchive(dataRow.id)}>456<UnArchiveIcon size={18} /></Button>{' '}</>}</>}
                                {archivePermissions === true && <>{typeof(dataRow.active) !== 'undefined' && dataRow.active === '1' && <Button size="sm" color="warning" onClick={() => onArchive(dataRow.id)}>123<ArchiveIcon size={18} /></Button>}</>}
                            </td>);
                        }
                        else
                        {
                            return (<td key={index}>{value}</td>);
                        }
                    })}
                </tr>);
            })}
        </>);
    }
    if (tableData.length === 0) {
        return (<tr>
            <td colSpan={columns.length} className="text-center">
                {languageText[languageCode].buttons["noRecords"]}
            </td>
        </tr>);
    }
}
const mapStateToProps = state => ({
    auth: state.auth.user,
    userPermissions: state.auth.user.userRole,
    data: state.pages.data,
});

export default connect(mapStateToProps, {archiveSubDataRecord})(DataList);
