import React, { Component, Fragment } from 'react';
import FormInputCheckboxElement from './FormInputCheckboxElement';
import classnames from "classnames";
import ShowInputError from "./ShowInputError";

class FormInputCheckbox extends Component {
    render () {
        const { name, label, colSize, helpText, valueOptions, selectedCheckboxes, handleCheckboxChange, error, field  } = this.props;
        let value = this.props.value;
        let divColSize;
        let colLength1;
        let colLength2;
        let displayDiv = (typeof(field.displayDiv) !== undefined)?field.displayDiv:'showDiv';
        let showCheckbox = (typeof(field.hideCheckbox) !== undefined && ['cities','limitAccess'].includes(name) && field.hideCheckbox)?false:true;

        if (colSize === 0) { divColSize = 0; colLength1 = "col-sm-4"; colLength2 = "col-sm-8"; }
        if (colSize === 1) { divColSize = 12; colLength1 = "col-sm-12"; colLength2 = "col-sm-12"; }
        if (colSize === 2) { divColSize = 6; colLength1 = "col-sm-4"; colLength2 = "col-sm-8"; }
        if (colSize === 3) { divColSize = 4; colLength1 = "col-sm-4"; colLength2 = "col-sm-8"; }
        if (colSize === 4) { divColSize = 8; colLength1 = "col-sm-4"; colLength2 = "col-sm-8"; }
        let optionsValue = '';
        if (typeof(value) !== 'undefined') {
            if (value === null) {
                value = [];
            }
            if (value === 'null') {
                value = [];
            }
            optionsValue = value.toString();
        }
        return (
            <div className={`col-${divColSize} ${displayDiv}`}>
                <div className="container p-0">
                    <div className="form-group row border-bottom pb-2">
                        <label htmlFor={name} className={colLength1}>{label}</label>
                        <div className={colLength2}>
                            {valueOptions ? (
                                valueOptions.map((options, index) => {
                                    options.name = options.name ? options.name : options.value;
                                    let checked = false;
                                    if (typeof(options.id) !== 'undefined') {
                                        checked = optionsValue.indexOf("," + options.id.toString() + ",") !== -1 ? 'checked' : false;
                                    }
                                    if (typeof(options.code) !== 'undefined') {
                                        checked = optionsValue.indexOf("," + options.code.toString() + ",") !== -1 ? 'checked' : false;
                                    }
                                    if (typeof(value) !== 'undefined' && value !== '0' && value !== 0) {
                                        if(name == "cities"){
                                            checked = optionsValue.indexOf("," + options.id.toString() + ",") !== -1 ? 'checked' : false;
                                        }else{
                                            checked = (optionsValue.indexOf('"' + options.id.toString() + '"') !== -1 || optionsValue.indexOf(options.id) !== -1) ? 'checked' : false;
                                        }
                                    }

                                    return (<div key={index} className="form-check form-check-inline px-3 checkbox-lable">
                                        <label className="form-check-label" htmlFor={(typeof(options.id) !== 'undefined')?options.id:options.code}>
                                            {showCheckbox && <>
                                            {typeof(options.id) !== 'undefined' && <FormInputCheckboxElement
                                                className={classnames('form-check-input', { 'is-invalid': error})}
                                                type="checkbox"
                                                checked={checked}
                                                name={name}
                                                id={options.id}
                                                value={options.id}
                                                selectedCheckboxes={selectedCheckboxes}
                                                handleCheckboxChange={handleCheckboxChange}
                                            />}
                                            {typeof(options.code) !== 'undefined' && <FormInputCheckboxElement
                                                className={classnames('form-check-input', { 'is-invalid': error})}
                                                type="checkbox"
                                                checked={checked}
                                                name={name}
                                                id={name + index}
                                                value={options.code}
                                                selectedCheckboxes={selectedCheckboxes}
                                                handleCheckboxChange={handleCheckboxChange}
                                            />}
                                            </>}
                                            {options.name}
                                        </label>
                                    </div>);
                                }))
                            : null}
                        </div>
                        {helpText && <Fragment><div className="col-4">&nbsp;</div><small id={name + "Help"} className="col-8 form-text text-muted">{helpText}</small></Fragment>}
                        {error && <ShowInputError error={error} />}
                    </div>
                </div>
            </div>
        )
    }
}
export default FormInputCheckbox
