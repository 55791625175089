import React from "react";
import { Container, Row, Col } from "reactstrap";
import {Link} from "react-router-dom";
import {languageText} from "../../config";
import {connect} from "react-redux";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { languageCode } = this.props.auth.user.user;
    return (<footer className="footer">
      <Container fluid>
        <Row className="text-muted">
          <Col xs="6" className="text-left">
            <ul className="list-inline">
              <li className="list-inline-item">
                <Link className="text-muted" to="/support-center">
                  {languageText[languageCode].footer.support}
                </Link>
              </li>
              <li className="list-inline-item">
                <Link className="text-muted" to="/help-center">
                  {languageText[languageCode].footer.helpCenter}
                </Link>
              </li>
              <li className="list-inline-item">
                <Link className="text-muted" to="/privacy">
                  {languageText[languageCode].footer.privacy}
                </Link>
              </li>
              <li className="list-inline-item">
                <Link className="text-muted" to="/terms-of-service">
                  {languageText[languageCode].footer.termsOfService}
                </Link>
              </li>
            </ul>
          </Col>
          <Col xs="6" className="text-right">
            <p className="mb-0">
              &copy; {new Date().getFullYear()} -{" "}
              <span href="/" className="text-muted">
              SeniorPlicity.com
            </span>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>);
  }
}

export default connect(store => ({
  app: store.app,
  auth: store.auth
})) (Footer);
