import React, {Component} from 'react';

import FormInputText from './FormInputText';
import FormInputGeoLocate from './FormInputGeoLocate';
import FormInputDisplay from './FormInputDisplay';
import FormInputTextArea from './FormInputTextArea';
import FormInputRadio from './FormInputRadio';
import FormInputFile from './FormInputFile';
import FormInputImage from './FormInputImage';
import FormInputSelect from './FormInputSelect';
import FormInputCheckbox from './FormInputCheckbox';
import FormInputEditor from './FormInputEditor';
import FormInputToggle from './FormInputToggle';
import FormInputPassword from './FormInputPassword';
import FormInputHidden from "./FormInputHidden";
import FormInputFileDrag from "./FormInputFileDrag";
import FormInputFancyCheckbox from "./FormInputFancyCheckbox";
import FormLinkedTable from "./FormLinkedTable";
import FormInputDate from "./FormInputDate";
import FormInputTime from "./FormInputTime";
import FormInputSimpleEditor from "./FormInputSimpleEditor";

class FormInput extends Component {
    render() {
        let showOneColumn = this.props.showOneColumn;
        if (typeof(showOneColumn) === 'undefined' || showOneColumn === '') showOneColumn='false';
        const {label, type} = this.props;

        switch(type) {
            case "time":
                return (<FormInputTime
                    {...this.props}
                />);
            case "date":
                return (<FormInputDate
                    {...this.props}
                />);
            case "display":
                return (<FormInputDisplay
                    {...this.props}
                />);
            case "text":
                return (<FormInputText
                    {...this.props}
                />);
            case "geoLocate":
                return (<FormInputGeoLocate
                    {...this.props}
                />);
            case "password":
                return (
                    <FormInputPassword
                        {...this.props}
                    />
                );
            case "editor":
            case "content":
                return (<FormInputEditor
                    {...this.props}
                />);
            case "simpleEditor":
                return (<FormInputSimpleEditor
                    {...this.props}
                />);
            case "textarea":
                return (<FormInputTextArea
                    {...this.props}
                />);
            case "number":
                return (<FormInputText
                    {...this.props}
                />);
            case "radio":
                return (<FormInputRadio
                    {...this.props}
                />);
            case "toggle":
                return (<FormInputToggle
                    {...this.props}
                />);
            case "checkbox":
                return (<FormInputCheckbox
                    {...this.props}
                />);
            case "fancyCheckbox":
                return (<FormInputFancyCheckbox
                    {...this.props}
                />);
            case "select":
            case "dropdown":
                return (<FormInputSelect
                    {...this.props}
                />);
            case "email":
                return (<FormInputText
                    {...this.props}
                />);

            case "upload":
            case "image":
                return (<FormInputImage
                    {...this.props}
                />);
            case "file":
                return (<FormInputFile
                    {...this.props}
                />);
            case "drag":
                return (<FormInputFileDrag
                    {...this.props}
                />);

            case "hidden":
                return (<FormInputHidden
                    {...this.props}
                />);

            case "title":
                return (<div className={`col-12 p-2 bg-secondary text-white mb-2`}>
                    <b>{label}</b>
                </div>);

            case "linkedTable":
                return (<FormLinkedTable
                    {...this.props}
                />);

            default:
                return (<FormInputText
                    {...this.props}
                />);
        }
    }
}

export default FormInput
