import React, {Component} from "react";
import {connect} from "react-redux";
import {loadSubscriptions} from "../client/actions/apiSubscriptionActions";
class Subscriptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageMode: "loading",
            featuredSubscriptions: [],
            sponsorSubscriptions: [],
        }
    }

    componentDidMount() {
        this.loadSubscriptions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            if (this.props.data.pageName === "loadSubscriptions") {
                this.setState({
                    featuredSubscriptions: this.props.data.featuredSubscriptions,
                    sponsorSubscriptions: this.props.data.sponsorSubscriptions
                })
            }
        }
    }

    loadSubscriptions = () => {
        this.props.loadSubscriptions();
    }

    viewSubscription = (subscriptionType, subscriptionId) => {
        console.log("subscriptionType, subscriptionId", subscriptionType, subscriptionId);
    }

    render() {
        const { featuredSubscriptions, sponsorSubscriptions } = this.state;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <h2>Featured Listing Subscriptions</h2>
                        <div className="card">
                            <div className="card-body">
                                <div className="input-group mb-4">
                                    <input type="text" placeholder="search" className="form-control" aria-label="Text input with segmented dropdown button" />
                                    <div className="input-group-append">
                                        <button className="btn btn-secondary" type="button">Search</button>
                                    </div>
                                </div>
                                <table className="table table-sm table-striped">
                                    <thead>
                                        <tr>
                                            <th>Company</th>
                                            <th className="text-right">Tools</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {featuredSubscriptions.map((subscription, index) => {
                                            return (<tr key={index}>
                                                <td>{subscription.comm_name}</td>
                                                <td className="text-right">
                                                    <button className="btn btn-sm btn-secondary" onClick={() => this.viewSubscription('featured', subscription.subscriptionId)}>View</button>
                                                </td>
                                            </tr>);
                                        })}
                                    </tbody>
                                </table>
                                <hr />

                                <nav aria-label="Page navigation example" className="mt-3">
                                    <ul className="pagination justify-content-center">
                                        <li className="page-item disabled">
                                            <a className="page-link">Previous</a>
                                        </li>
                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">Next</a>
                                        </li>
                                    </ul>
                                </nav>

                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <h2>Sponsor Subscriptions</h2>
                        <div className="card">
                            <div className="card-body">
                                <div className="input-group mb-4">
                                    <input type="text" placeholder="search" className="form-control" aria-label="Text input with segmented dropdown button" />
                                    <div className="input-group-append">
                                        <button className="btn btn-secondary" type="button">Search</button>
                                    </div>
                                </div>

                                <table className="table table-sm table-striped">
                                    <thead>
                                        <tr>
                                            <th>Company</th>
                                            <th className="text-right">Tools</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sponsorSubscriptions.map((subscription, index) => {
                                            return (<tr key={index}>
                                                <td>{subscription.comm_name}</td>
                                                <td className="text-right">
                                                    <button className="btn btn-sm btn-secondary" onClick={() => this.viewSubscription('sponsor', subscription.subscriptionId)}>View</button>
                                                </td>
                                            </tr>);
                                        })}
                                    </tbody>
                                </table>
                                <hr />

                                <nav aria-label="Page navigation example" className="mt-3">
                                    <ul className="pagination justify-content-center">
                                        <li className="page-item disabled">
                                            <a className="page-link">Previous</a>
                                        </li>
                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">Next</a>
                                        </li>
                                    </ul>
                                </nav>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth.user,
    userPermissions: state.auth.user.userRole,
    data: state.pages.data,
});

export default connect(mapStateToProps, { loadSubscriptions })(Subscriptions);
