import React, {Fragment} from 'react';
import classnames from 'classnames';
import ShowInputError from "./ShowInputError";

const FormInputSelect = ({name, value, colSize, defaultValue, field, showOneColumn, label, placeholder, type, helpText, dropdown, onChange, error}) => {
    if (typeof(showOneColumn) === 'undefined' || showOneColumn === '') showOneColumn='false';
    let divColSize;
    if (colSize === 0) divColSize = 0;
    if (colSize === 1) divColSize = 12;
    if (colSize === 2) divColSize = 6;
    if (colSize === 3) divColSize = 4;
    if (colSize === 4) divColSize = 8;
    if (
        (value === '' || typeof(value) === 'undefined') &&
        defaultValue !== '' && typeof(defaultValue) !== 'undefined'
    ) {
        value = defaultValue;
    }

    return (<div className={`col-12 col-sm-${divColSize}`}>
        <div className="container p-0">
            <div className="form-group row border-bottom pb-2">
                <label className="col-sm-12" htmlFor={name}>{label}</label>
                <div className="col-sm-12">
                    <select
                        className={classnames('form-control form-control-sm', { 'is-invalid': error})}
                        id={name}
                        name={name}
                        value={value}
                        onChange={(e) => onChange(type, e, field)}
                    >
                        <option value="">-- SELECT --</option>
                        {dropdown &&  <Fragment>
                            {typeof(dropdown.length) !== 'undefined' && <Fragment>
                                {dropdown.length > 0 && <Fragment>
                                    {dropdown.map((options, index) => {
                                        return (<Fragment key={index}>
                                            {options.Name  !== '' && typeof(options.Name) != 'undefined' && <option value={options.ID}>{options.Name}</option>}
                                            {options.Name  !== '' && typeof(options.Name) != 'undefined' && <option value={options.ID}>{options.Name}</option>}
                                            {options.name  !== '' && typeof(options.name) != 'undefined' && <option value={options.id}>{options.name}</option>}
                                            {(options.Name === '' || typeof(options.Name) === 'undefined') && (options.name === '' && typeof(options.name) === 'undefined') && <option value={index}>{options}</option>}
                                        </Fragment>);
                                    })}
                                </Fragment>}
                            </Fragment>}
                        </Fragment>}
                    </select>
                </div>
                {helpText && <small id={name + "Help"} className="col-12 form-text text-muted">{helpText}</small>}
                {error && <ShowInputError error={error} />}
            </div>
        </div>
    </div>);
};

export default FormInputSelect