import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { getConfigSettings } from "../../client/actions/apiActions";
import { login } from "../../client/actions/auth";
import { Redirect } from "react-router-dom";
import { Button, Card, CardBody, Form, FormGroup, Label, Input } from "reactstrap";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialDataLoaded: false,
      username: '',
      password: '',
      loginDisclaimerMessage: '',
      errorMessage: '',
      isAuthenticated: false
    };
  };

  componentDidMount() {
    this.props.getConfigSettings();
    this.makeFocus();
  }
  componentDidUpdate(prevProps, prevState, snapshot)
  {
    if (prevProps !== this.props)
    {
      if (typeof(this.props.auth) !== 'undefined' && this.props.auth !== null && this.props.auth !== '')
      {
        if (typeof(this.props.auth.details) !== 'undefined')
        {
          this.setState({errorMessage: this.props.auth.details});
          this.makeFocus();
        }
        else if (typeof(this.props.auth.token) !== 'undefined')
        {
          this.setState({"isAuthenticated": true});
        }
        else
        {
          console.log("Failed");
        }
      }
    }
  }
  makeFocus = () => {
    try {
      window.username.focus();
    }
    catch {

    }
  };
  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value, errorMessage: ''});
  }
  onKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (this.state.username !== '' && this.state.password !== '') {
        this.onSubmit();
      }
    }
  };
  onSubmit = () => {
    const { username, password } = this.state;
    this.props.login( username, password);
  };
  onFormSubmit = (e) => {
    e.preventDefault();
  };

  render() {
    const {errorMessage, username, password, isAuthenticated } = this.state;
    if (isAuthenticated === true) {
      return (<Redirect to="/" />);
    }
    else {
      return (<React.Fragment>
          <div className="text-center mt-4">
            <img
                src="/assets/images/logo.png"
                alt="Seniorplicity"
                className="img-fluid rounded-circle"
                width="132"
                height="132"
            />
            <h2>SeniorPlicity</h2>
            <small>Control Panel</small>
          </div>
          <Card>
            <CardBody>
              <div className="m-sm-4">
                <div className="text-center">
                  <h1 className="h2">Account sign in</h1>
                  <p className="lead">Sign in to your account to continue</p>
                  { errorMessage && <div className="alert alert-danger alert-dismissible fade show" role="alert">
                    <div className="alert-message">
                      {errorMessage}
                    </div>
                  </div>}
                </div>
                <Form onSubmit={() => this.onFormSubmit()}>
                  <FormGroup>
                    <Label>Email</Label>
                    <Input
                        bsSize="lg"
                        type="email"
                        id="username"
                        name="username"
                        value={username}
                        placeholder="Enter your email"
                        autoComplete="username"
                        onChange={this.onChange}
                        onKeyPress={this.onKeyPress}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Password</Label>
                    <Input
                        bsSize="lg"
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        placeholder="Enter your password"
                        autoComplete="current-password"
                        onChange={this.onChange}
                        onKeyPress={this.onKeyPress}
                    />
                    <small>
                      <Link to="/auth/reset-password">Forgot password?</Link>
                    </small>
                  </FormGroup>
                  <div className="text-center mt-3">
                      <Button color="primary" size="lg" onClick={() => this.onSubmit()}>
                        Sign in
                      </Button>
                  </div>
                </Form>
              </div>
            </CardBody>
          </Card>
        </React.Fragment>);
    }
  }
}

const mapStateToProps = state => ({
  isAuthenticated : state.auth.isAuthenticated,
  auth: state.auth.user
});

export default connect(mapStateToProps, {login, getConfigSettings} )(SignIn);