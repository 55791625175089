import React, {Component} from 'react';

class InviteUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listingCheckedList: [],
            hideSave: true,
            emailAddress: '',
            showSaving: true,
            userRole: ''
        }
    }
    componentDidMount() {
        //console.log("props", this.props);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    onInviteUser = (e) => {
        e.preventDefault();
        if (this.state.hideSave) {
            // not ready to invite user until valid email
        } else {
            this.setState({
                showSaving:true
            });

            let saveInfo = [];
            saveInfo["userRole"] = this.state.userRole;
            saveInfo["emailAddress"] = this.state.emailAddress;

            this.props.modalFunctionController('sendInviteUserToTeam', saveInfo);
        }
    };

    onChange = (e) => {
        let hideSave = true;
        if (e.target.name === 'emailAddress') {
            hideSave = !(this.state.userRole !== '' && this.validateEmail(e.target.value));
        }
        if (e.target.name === 'userRole') {
            hideSave = !(e.target.value !== '' && this.validateEmail(this.state.emailAddress));
        }

        this.setState({
            [e.target.name] : e.target.value,
            hideSave,
            successMessage: '',
            errorMessage: ''
        });
    }

    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    render() {
        const { modalData } = this.props;
        const { hideSave } = this.state;
        return (
            <form onSubmit={this.onInviteUser}>
                <div className="container" style={{minWidth: '300px', maxWidth: '100%'}}>

                    <div className="row">
                        <div className="col-12">
                            {modalData.successMessage && <div className="alert alert-success p-3">
                                {modalData.successMessage}
                            </div>}
                            {modalData.errorMessage && <div className="alert alert-danger p-3">
                                {modalData.errorMessage}
                            </div>}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="userRole">User Role</label>
                                <select className="form-control" id="userRole" name="userRole" aria-describedby="userHelp" onChange={this.onChange} value={this.state.userRole}>
                                    <option value="">-- select --</option>
                                    <option value="3">Sales Director</option>
                                    <option value="2">Regional</option>
                                    <option value="1">Admin</option>
                                </select>
                                <small id="emailHelp" className="form-text text-muted">Select the role you want to assign to this new user.</small>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Email Address</label>
                                <input type="email" className="form-control" id="emailAddress" name="emailAddress" placeholder="enter email address" aria-describedby="emailHelp" onChange={this.onChange} value={this.state.emailAddress} />
                                <small id="emailHelp" className="form-text text-muted">Confirm the email address of the person you want to invite to your team</small>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-6">
                            <button type="submit" className={hideSave === true ? "btn btn-primary btn-sm disabled" : "btn btn-primary btn-sm"} onClick={this.onInviteUser}>Send Invite</button>
                        </div>
                        <div className="col-6 text-right">
                            <button
                                className="btn btn-secondary btn-sm"
                                onClick={this.props.closeModalWindow}
                            >Close</button>
                        </div>
                    </div>

                </div>
            </form>
        );
    }
}


export default InviteUser;
